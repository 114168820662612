<template>
  <v-app :height="height" style="min-height: 100vh; background-color: black;">
    <!-- <Header @toggle-sheet="toggleSheet" /> -->
      <div id="app">
          <!-- <HelloWorld v-if="showHelloWorld && !$route.path.includes('/register') && !$route.path.includes('/confirmation') && !$route.path.includes('/raffle') && !$route.path.includes('/landing') && !$route.path.includes('/dashboard') && !$route.path.includes('/ConfirmationFillUp') && !$route.path.includes('/nomination')"  /> -->
          <router-view/>
      </div>
  </v-app>
</template>


<script>
  import HelloWorld from './components/HelloWorld.vue';
  import Header from './components/Header.vue';
  import './assets/css/scrollbar.css';
  import Swal from 'sweetalert2';
  import { mapGetters } from 'vuex';
  // import NotFoundComponentVue from './components/NotFoundComponent.vue';

  export default {
    components: {
      HelloWorld, 
      Header,
      // NotFoundComponentVue,
    },
    name: 'App',
    data: () => ({
      sheet: false,
      showHelloWorld: true,
    }),
    computed: {
      ...mapGetters(['GET_EVENT_ID','GET_PREFERENCES_NEW_DATA']),
      height() {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 220;
          case 'sm': return 400;
          case 'md': return 500;
          case 'lg': return 600;
          case 'xl': return 800;
        }
      },
    },
    async created() {
      if (this.GET_EVENT_ID) {
        await this.loadPreferences();
      }
    },
    methods: {
      async loadPreferences() {
        let payload = {
          url: 'moduleSettings',
          type: 'Preferences',
          event_id: this.GET_EVENT_ID
        };
        this.$store.dispatch('urlGet', payload).then((response) => {
          this.$store.commit('PREFERENCES', response);
          if (response.length > 0) {
            response.forEach(x => {
              if (x.value !== null) {
                document.documentElement.style.setProperty(`--${x.parameter}`, x.value);
              }
            });
          }
        });
      },
      toggleSheet() {
        if (this.$route.path.includes('/confirmation') || this.$route.path.includes('/raffle') || this.$route.path.includes('/INGCONight')) {
          this.showHelloWorld = false;
        } else {
          this.sheet = !this.sheet;
          this.toggleHelloWorld();
        }
      },
      closeSheet() {
        this.sheet = false;
      },
      toggleHelloWorld() {
        this.showHelloWorld = !this.showHelloWorld;
      },
    },
    mounted() {
      if (!this.GET_EVENT_ID) {
        this.$store.dispatch('credentials').then((res) => {
          // Additional logic after getting credentials
        }).catch((error) => {
          if (error.response.status == 401) {
            Swal.fire({
              title: 'Unauthenticated!',
              icon: 'error',
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage.removeItem("token");
                this.$router.push('/');
              }
            });
          }
        });
      }
    },
    watch: {
      GET_EVENT_ID: {
        async handler(val) {
          if (val) {
            await this.loadPreferences(); 
          }
        },
        immediate: true, 
      },
      GET_PREFERENCES_NEW_DATA:{
        handler(val) {
          if (val) {
            this.loadPreferences(); 
            this.$store.commit('PREFERENCES_NEW_DATA',false)
          }
        },
      },
      
    },
  };
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Kaushan+Script&display=swap');
    @import '/src/assets/css/HelloWorld.css';
    

</style>

