<template>
    <div class="container" width="100%"> 
        
        <div class="fixed-tabs-container" >
            <v-tabs v-model="activeTab" class="fixed-tabs d-flex justify-left align-center custom-tabs ml-10">
                <v-tab><h3>Client Settings</h3></v-tab>
                <v-tab><h3>Admin Settings</h3></v-tab>
            </v-tabs>
        </div>
        
        <v-card width="100%" class="content-card" height="auto">
        <v-tabs-items v-model="activeTab" class="scrollable-content" >
            <v-tab-item >
                <v-container fluid >
                    <v-row no-gutters class=" text-center d-flex justify-leftx" >
                        <v-col cols="12" sm="2" class="ml-10">
                            <h4>System Font-family Primary</h4>
                            <v-autocomplete
                                v-model="form.system_font_family_primary"
                                :items="GET_ALL.font_family"
                                outlined
                                dense
                            ></v-autocomplete>
                        </v-col>
                        
                        
                        <v-col cols="12" sm="2" class="ml-2">
                            <h4>System Toolbar Image</h4>
                                <v-card color="white">
                                    <v-container fluid class="picture" v-if="!hasUploaded">
                                        <v-img :src="checkImagePath(preview.toolbar_image)" contain aspect-ratio="1.7">
                                        </v-img>
                                    </v-container>
                                    <v-container fluid class="picture" v-else v-for="(image, i) in preview.toolbar_image" :key="i">
                                        <v-img :src="image" contain aspect-ratio="1.7">
                                        </v-img>
                                    </v-container>
                                </v-card>
                            <FileUploadComponent ref="fileUpload" :label="''" @uploadedData="uploadedData" :obj_key="'system_toolbar_image'" :previewKey="'toolbar_image'" :showPreview="true" @reset="clearFileUpload" />
                        </v-col>
                        
                        <v-col cols="12" sm="2" class="ml-2">
                            <h4>System Landing Image</h4>
                                <v-card color="white">
                                    <v-container fluid class="picture" v-if="!hasUploadedLanding">
                                        <v-img :src="checkImagePath(preview.landing_image)" contain aspect-ratio="1.7">
                                        </v-img>
                                    </v-container>
                                    <v-container fluid class="picture" v-else v-for="(image, i) in preview.landing_image" :key="i">
                                        <v-img :src="image" contain aspect-ratio="1.7">
                                        </v-img>
                                    </v-container>
                                </v-card>
                            <FileUploadComponent ref="fileUpload" :label="''" @uploadedData="uploadedData" :obj_key="'system_landing_image'" :previewKey="'landing_image'" :showPreview="true" @reset="clearFileUpload" />
                        </v-col>
                        <v-col cols="12" sm="2" class="ml-2">
                            <h4>Lazy Loading Image</h4>
                                <v-card color="white">
                                    <v-container fluid class="picture" v-if="!hasUploadedLandings">
                                        <v-img :src="checkImagePath(preview.lazy_image)" contain aspect-ratio="1.7">
                                        </v-img>
                                    </v-container>
                                    <v-container fluid class="picture" v-else v-for="(image, i) in preview.lazy_image" :key="i">
                                        <v-img :src="image" contain aspect-ratio="1.7">
                                        </v-img>
                                    </v-container>
                                </v-card>
                            <FileUploadComponent ref="fileUpload" :label="''" @uploadedData="uploadedData" :obj_key="'system_lazy_image'" :previewKey="'lazy_image'" :showPreview="true" @reset="clearFileUpload" />
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="d-flex text-center justify-left align-center">
                        <v-col cols="2" sm="2" class="ml-10">
                            <h4>System Toolbar Color</h4>
                            <v-color-picker
                                v-model="form.system_toolbar_color"
                                mode="hexa"
                            ></v-color-picker>
                        </v-col>
                        <v-col cols="2" sm="2" class="ml-2">
                            <h4>System Toolbar Font Color</h4>
                            <v-color-picker
                                v-model="form.system_toolbar_font_color_main"
                                mode="hexa"
                            ></v-color-picker>
                        </v-col>
                        <v-col cols="2" sm="2" class="ml-2">
                            <h4>System Toolbar Icon Color</h4>
                            <v-color-picker
                                v-model="form.system_toolbar_icon_color"
                                mode="hexa"
                            ></v-color-picker>
                        </v-col>
                        <v-col cols="2" sm="2" class="ml-2">
                            <h4>System Main Label Color</h4>
                            <v-color-picker
                                v-model="form.system_main_label_color"
                                mode="hexa"
                            ></v-color-picker>
                        </v-col>
                        
                        <v-col cols="2" sm="2" class="ml-2">
                            <h4>System Secondary Label Color</h4>
                            <v-color-picker
                                v-model="form.system_secondary_label_color"
                                mode="hexa"
                            ></v-color-picker>
                        </v-col>
                        <v-col cols="2" sm="2" class="ml-10">
                            <h4>System Guest Information Color</h4>
                            <v-color-picker
                                v-model="form.system_guest_information_color"
                                mode="hexa"
                            ></v-color-picker>
                        </v-col>
                        <v-col cols="12" sm="2" class="ml-2">
                            <h4>Log Out Dotted Color</h4>
                            <v-color-picker
                                v-model="form.border_dotted_log_out"
                                mode="hexa"
                            ></v-color-picker>
                        </v-col>
                        <v-col cols="12" sm="2" class="ml-2">
                            <h4>Log Out Text Button Color</h4>
                            <v-color-picker
                                v-model="form.system_font_color_secondary_log_out"
                                mode="hexa"
                            ></v-color-picker>
                        </v-col>
                        <v-col cols="12" sm="2" class="ml-2">
                            <h4>Main Icon Color</h4>
                            <v-color-picker
                                v-model="form.icon_color_main"
                                mode="hexa"
                            ></v-color-picker>
                        </v-col>
                        <v-col cols="12" sm="2" class="ml-2">
                            <h4>Log In Button Text Color</h4>
                            <v-color-picker
                                v-model="form.log_in_button_text_color"
                                mode="hexa"
                            ></v-color-picker>
                        </v-col>
                        <v-col cols="12" sm="2" class="ml-10">
                            <h4>Submit Button Text Color</h4>
                            <v-color-picker
                                v-model="form.submit_button_text_color"
                                mode="hexa"
                            ></v-color-picker>
                        </v-col>
                        <v-col cols="12" sm="2" class="ml-2">
                            <h4>Window Action Button Color</h4>
                            <v-color-picker
                                v-model="form.window_action_button_color"
                                mode="hexa"
                            ></v-color-picker>
                        </v-col>
                        <v-col cols="12" sm="2" class="ml-2">
                            <h4>Carousel Action Button Text Color</h4>
                            <v-color-picker
                                v-model="form.window_action_button_text_color"
                                mode="hexa"
                            ></v-color-picker>
                        </v-col>
                        <v-col cols="12" sm="2" class="ml-2">
                            <h4>Carousel Navigate Button</h4>
                            <v-color-picker
                                v-model="form.window_carousel_navigate_button_color"
                                mode="hexa"
                            ></v-color-picker>
                        </v-col>
                        <v-col cols="12" sm="2" class="ml-2">
                            <h4>Carousel Navigate Button Text Color</h4>
                            <v-color-picker
                                v-model="form.window_carousel_navigate_text_color"
                                mode="hexa"
                            ></v-color-picker>
                        </v-col>
                        <v-col cols="12" sm="2" class="ml-10">
                            <h4>System Body Color</h4>
                            <v-color-picker
                                v-model="form.system_body_color"
                                mode="hexa"
                            ></v-color-picker>
                        </v-col>
                        <v-col cols="12" sm="2" class="ml-2">
                            <h4>Expansion Title Color</h4>
                            <v-color-picker
                                v-model="form.expansion_title_color"
                                mode="hexa"
                            ></v-color-picker>
                        </v-col>
                        <v-col cols="12" sm="2" class="ml-2">
                            <h4>System Dialog Border Color</h4>
                            <v-color-picker
                                v-model="form.system_dialog_border"
                                mode="hexa"
                            ></v-color-picker>
                        </v-col>
                        <v-col cols="12" sm="2" class="ml-2">
                            <h4>Swal Border</h4>
                            <v-color-picker
                                v-model="form.swal_border"
                                mode="hexa"
                            ></v-color-picker>
                        </v-col>
                        <v-col cols="12" sm="2" class="ml-2">
                            <h4>Swal Color</h4>
                            <v-color-picker
                                v-model="form.swal_color"
                                mode="hexa"
                            ></v-color-picker>
                        </v-col>
                        <v-col cols="12" sm="2" class="ml-10">
                            <h4>Swal background Color</h4>
                            <v-color-picker
                                v-model="form.swal_background_color"
                                mode="hexa"
                            ></v-color-picker>
                        </v-col>
                        <v-col cols="12" sm="2" class="ml-2">
                            <h4>Swal Icon Color</h4>
                            <v-color-picker
                                v-model="form.swal_icon_color"
                                mode="hexa"
                            ></v-color-picker>
                        </v-col>
                        <v-col cols="12" sm="2" class="ml-2">
                            <h4>Background Color</h4>
                            <v-color-picker
                                v-model="form.system_main_background_color"
                                mode="hexa"
                            ></v-color-picker>
                        </v-col>
                        <v-col cols="12" sm="2" class="ml-2">
                            <h4>Expansion Color</h4>
                            <v-color-picker
                                v-model="form.system_expansion_color"
                                mode="hexa"
                            ></v-color-picker>
                        </v-col>
                        <v-col cols="12" sm="2" class="ml-2">
                            <h4>Expansion Background Color</h4>
                            <v-color-picker
                                v-model="form.system_expansion_background"
                                mode="hexa"
                            ></v-color-picker>
                        </v-col>
                    </v-row>
                </v-container>
            </v-tab-item>
        <v-tab-item>
            <v-container>
                <v-row no-gutters class=" text-center d-flex justify-left">
                    <v-col cols="12" sm="2" class="ml-10">
                            <h4>System Font-family Primary</h4>
                            <v-autocomplete
                                v-model="form.system_admin_font_family_primary"
                                :items="GET_ALL.font_family"
                                outlined
                                dense
                            ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="2" class="ml-2">
                        <h4>Your App Font Style</h4>
                        <v-autocomplete
                            v-model="form.system_yourapp_font_style"
                            :items="GET_ALL.font_family"
                            outlined
                            dense
                        ></v-autocomplete>
                    </v-col>       
                    <v-col cols="12" sm="2" class="ml-2">
                            <h4>Nominee Background Image</h4>
                                <v-card color="white">
                                    <v-container fluid class="picture" v-if="!hasupload">
                                        <v-img :src="checkImagePath(preview.nominee_bg_image)" contain aspect-ratio="1.7">
                                        </v-img>
                                    </v-container>
                                    <v-container fluid class="picture" v-else v-for="(image, i) in preview.nominee_bg_image" :key="i">
                                        <v-img :src="image" contain aspect-ratio="1.7">
                                        </v-img>
                                    </v-container>
                                </v-card>
                            <FileUploadComponent ref="fileUpload" :label="''" @uploadedData="uploadedData" :obj_key="'system_nominee_bg_image'" :previewKey="'nominee_bg_image'" :showPreview="true" @reset="clearFileUpload" />
                        </v-col>
                        <v-col cols="12" sm="2" class="ml-2">
                            <h4>App Background Image</h4>
                                <v-card color="white">
                                    <v-container fluid class="picture" v-if="!hasuploads">
                                        <v-img :src="checkImagePath(preview.app_image)" contain aspect-ratio="1.7">
                                        </v-img>
                                    </v-container>
                                    <v-container fluid class="picture" v-else v-for="(image, i) in preview.app_image" :key="i">
                                        <v-img :src="image" contain aspect-ratio="1.7">
                                        </v-img>
                                    </v-container>
                                </v-card>
                            <FileUploadComponent ref="fileUpload" :label="''" @uploadedData="uploadedData" :obj_key="'system_app_image'" :previewKey="'app_image'" :showPreview="true" @reset="clearFileUpload" />
                        </v-col>
                        <v-col cols="12" sm="2" class="ml-2">
                            <h4>Raffle Jar</h4>
                                <v-card color="white">
                                    <v-container fluid class="picture" v-if="!hasuploadss">
                                        <v-img :src="checkImagePath(preview.raffle_image)" contain aspect-ratio="1.7">
                                        </v-img>
                                    </v-container>
                                    <v-container fluid class="picture" v-else v-for="(image, i) in preview.raffle_image" :key="i">
                                        <v-img :src="image" contain aspect-ratio="1.7">
                                        </v-img>
                                    </v-container>
                                </v-card>
                            <FileUploadComponent ref="fileUpload" :label="''" @uploadedData="uploadedData" :obj_key="'system_raffle_image'" :previewKey="'raffle_image'" :showPreview="true" @reset="clearFileUpload" />
                        </v-col>
                        <v-col cols="12" sm="2" class="ml-10">
                            <h4>Winner Background</h4>
                                <v-card color="white">
                                    <v-container fluid class="picture" v-if="!hasuploadsss">
                                        <v-img :src="checkImagePath(preview.winner_bg)" contain aspect-ratio="1.7">
                                        </v-img>
                                    </v-container>
                                    <v-container fluid class="picture" v-else v-for="(image, i) in preview.winner_bg" :key="i">
                                        <v-img :src="image" contain aspect-ratio="1.7">
                                        </v-img>
                                    </v-container>
                                </v-card>
                            <FileUploadComponent ref="fileUpload" :label="''" @uploadedData="uploadedData" :obj_key="'system_winner_bg'" :previewKey="'winner_bg'" :showPreview="true" @reset="clearFileUpload" />
                        </v-col>
                        <v-col cols="12" sm="2" class="ml-2">
                            <h4>Ticket Image</h4>
                                <v-card color="white">
                                    <v-container fluid class="picture" v-if="!hasuploadssss">
                                        <v-img :src="checkImagePath(preview.ticket_image)" contain aspect-ratio="1.7">
                                        </v-img>
                                    </v-container>
                                    <v-container fluid class="picture" v-else v-for="(image, i) in preview.ticket_image" :key="i">
                                        <v-img :src="image" contain aspect-ratio="1.7">
                                        </v-img>
                                    </v-container>
                                </v-card>
                            <FileUploadComponent ref="fileUpload" :label="''" @uploadedData="uploadedData" :obj_key="'system_ticket_image'" :previewKey="'ticket_image'" :showPreview="true" @reset="clearFileUpload" />
                        </v-col>
                        <v-col cols="12" sm="2" class="ml-2">
                            <h4>Suspense Box</h4>
                                <v-card color="white">
                                    <v-container fluid class="picture" v-if="!hasUploadsssss">
                                        <v-img :src="checkImagePath(preview.suspense_image)" contain aspect-ratio="1.7">
                                        </v-img>
                                    </v-container>
                                    <v-container fluid class="picture" v-else v-for="(image, i) in preview.suspense_image" :key="i">
                                        <v-img :src="image" contain aspect-ratio="1.7">
                                        </v-img>
                                    </v-container>
                                </v-card>
                            <FileUploadComponent ref="fileUpload" :label="''" @uploadedData="uploadedData" :obj_key="'system_suspense_image'" :previewKey="'suspense_image'" :showPreview="true" @reset="clearFileUpload" />
                        </v-col>
                </v-row>
                <v-row no-gutters class=" text-center d-flex justify-left">     
                    <v-col cols="12" sm="2" class="ml-10">
                        <h4>System Admin Toolbar Color</h4>
                        <v-color-picker
                            v-model="form.system_admin_toolbar_color"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-2">
                        <h4>System Admin Font Toolbar Color</h4>
                        <v-color-picker
                            v-model="form.system_admin_font_toolbar_color"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-2">
                        <h4>System Admin Icon Toolbar Color</h4>
                        <v-color-picker
                            v-model="form.system_admin_icon_toolbar_color"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-2">
                        <h4>System Admin Background Color</h4>
                        <v-color-picker
                            v-model="form.system_admin_background_color"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-2">
                        <h4>System Sub Admin Toolbar Color</h4>
                        <v-color-picker
                            v-model="form.system_sub_admin_toolbar_color"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-10">
                        <h4>Total Sub Font Toolbar Color</h4>
                        <v-color-picker
                            v-model="form.system_sub_font_toolbar_color"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    
                    <v-col cols="12" sm="2" class="ml-2">
                        <h4>System Table Color</h4>
                        <v-color-picker
                            v-model="form.system_admin_table_color"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>

                    
                    <v-col cols="12" sm="2" class="ml-2">
                        <h4>Total Pax Card Color</h4>
                        <v-color-picker
                            v-model="form.system_totalpax_card_color"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-2">
                        <h4>Total Attended Card Color</h4>
                        <v-color-picker
                            v-model="form.system_totalattended_card_color"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-2">
                        <h4>Not Attended Card Color</h4>
                        <v-color-picker
                            v-model="form.system_notattended_card_color"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-10">
                        <h4>Total Percent Card Color</h4>
                        <v-color-picker
                            v-model="form.system_totalpercent_card_color"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-2">
                        <h4>Total Company Card Color</h4>
                        <v-color-picker
                            v-model="form.system_totalcompany_card_color"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-2">
                        <h4>Table Button</h4>
                        <v-color-picker
                            v-model="form.system_admin_button_color"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-2">
                        <h4>Table Text Button</h4>
                        <v-color-picker
                            v-model="form.system_text_button_color"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-2">
                        <h4>Table Data Color</h4>
                        <v-color-picker
                            v-model="form.system_textdata_color"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-10">
                        <h4>Table Header Color</h4>
                        <v-color-picker
                            v-model="form.system_tableheader_color"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-2">
                        <h4>Dialog Color</h4>
                        <v-color-picker
                            v-model="form.system_dialog_color"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-2">
                        <h4>Dialog Text Color</h4>
                        <v-color-picker
                            v-model="form.system_dialog_text_color"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-2">
                        <h4>Dialog Button Color</h4>
                        <v-color-picker
                            v-model="form.system_dialog_button_color"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-2">
                        <h4>Dialog Font Color</h4>
                        <v-color-picker
                            v-model="form.system_dialog_font_color"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-10">
                        <h4>Raffle Button Color</h4>
                        <v-color-picker
                            v-model="form.system_raffle_button"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-2">
                        <h4>Raffle Confetti Color</h4>
                        <v-color-picker
                            v-model="form.system_raffle_confetti_color"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-2">
                        <h4>Raffle Confetti Color 2</h4>
                        <v-color-picker
                            v-model="form.system_raffle_confetti_color_two"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-2">
                        <h4>Raffle Winner Text Color</h4>
                        <v-color-picker
                            v-model="form.system_raffle_winner_text_color"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-2">
                        <h4>Raffle Winner Text Color 2</h4>
                        <v-color-picker
                            v-model="form.system_raffle_winner_text_color_two"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-10">
                        <h4>Raffle Suspense Card Color</h4>
                        <v-color-picker
                            v-model="form.system_raffle_suspense_card_color"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-2">
                        <h4>Raffle Suspense Card Color 2</h4>
                        <v-color-picker
                            v-model="form.system_raffle_suspense_card_color_two"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-2">
                        <h4>Raffle Suspense Card Color 3</h4>
                        <v-color-picker
                            v-model="form.system_raffle_suspense_card_color_three"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="2" class="ml-2">
                        <h4>Redirect Button Color</h4>
                        <v-color-picker
                            v-model="form.system_redirect_button_color"
                            mode="hexa"
                        ></v-color-picker>
                    </v-col>

                </v-row>
            </v-container>
        </v-tab-item>
    </v-tabs-items>
        
    </v-card>
</div>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from 'vuex';
import moment from 'moment';
import FileUploadComponent from '@/components/shared/FileUploadComponent.vue'
import SharedFunctionsComponentVue from '@/components/shared/SharedFunctionsComponent.vue';
export default {
    mixins: [SharedFunctionsComponentVue],
    props: ['event_id'],
    data(){
        return {
            activeTab: 0,
            loaded: false,
            totalItems: 0,
            options: {},
            search: "",
            filter:{
                search:""
            },
            active_bread_crumb:'status',
            tab: null,
            items:[],
            settings_form:[],
            job_title_selection :[],
            form:{
                system_toolbar_color: '',
                system_toolbar_font_color_main: '',
                border_dotted_log_out: '',
                system_font_color_secondary_log_out: '',
                system_font_family_primary: '',
                system_toolbar_icon_color: '',
                system_main_label_color: '',
                system_secondary_label_color: '',
                system_guest_information_color: '',
                log_in_button_text_color: '',
                icon_color_main: '',
                submit_button_text_color: '',
                window_action_button_color: '',
                window_action_button_text_color: '',
                window_carousel_navigate_button_color: '',
                system_body_color: '',
                expansion_title_color: '',
                system_dialog_border: '',
                window_carousel_navigate_text_color: '',
                system_toolbar_image: null,
                system_landing_image: null,
                system_lazy_image:null,
                system_app_image:null,
                system_nominee_bg_image:null,
                system_raffle_image:null,
                system_winner_bg:null,
                system_ticket_image:null,
                system_suspense_image:null,
                swal_border: '',
                swal_color: '',
                swal_background_color: '',
                swal_icon_color: '',
                system_main_background_color: '',
                system_expansion_color:'',
                system_expansion_background:'',
                system_admin_toolbar_color:'',
                system_admin_font_toolbar_color:'',
                system_admin_icon_toolbar_color:'',
                system_admin_background_color:'',
                system_sub_admin_toolbar_color:'',
                system_admin_table_color:'',
                system_totalpax_card_color:'',
                system_totalattended_card_color:'',
                system_notattended_card_color:'',
                system_totalpercent_card_color:'',
                system_totalcompany_card_color:'',
                system_sub_font_toolbar_color:'',
                system_admin_button_color:'',
                system_text_button_color:'',
                system_textdata_color:'',
                system_tableheader_color:'',
                system_dialog_color:'',
                system_dialog_text_color:'',
                system_dialog_button_color:'',
                system_dialog_font_color:'',
                system_raffle_button:'',
                system_raffle_confetti_color:'',
                system_raffle_confetti_color_two:'',
                system_raffle_winner_text_color:'',
                system_raffle_winner_text_color_two:'',
                system_raffle_suspense_card_color:'',
                system_raffle_suspense_card_color_two:'',
                system_raffle_suspense_card_color_three:'',
                system_redirect_button_color:'',
                system_admin_font_family_primary:'',
                system_yourapp_font_style:'',
                
            },
            monthsArray: [],
            currentSettings: [],
            isMounted: false,
            votingIsDisabled: false,
            selected_key: null,
            tab: 0,
            preview:{
                landing_image: null,
                toolbar: null,
                app_image:null, 
                lazy_image:null,
            },
            breadcrumbs:[
                {
                    text: 'Client Side',
                    disabled: true,
                },
                {
                    text: 'Admin Side',
                    disabled: true,
                },
            ],
            hasUploaded: false,
            hasUploadedLanding: false,
            hasUploadedLandings: false,
            hasupload:false,
            hasuploads:false,
            hasuploadss:false,
            hasuploadsss:false,
            hasuploadssss:false,
            hasuploadsssss:false,
        }
    },
    async mounted(){
        await this.getAllPreferenceSettings();
        this.getFilters()
        this.checkEndNomination();
    },
    computed:{
        ...mapGetters([
            'DIALOGS',
            'GET_NEW_DATA',
            'GET_EVENT_ID',
            'GET_ALL'
            // 'GET_FORM_SETTINGS'
        ])
    },
    components:{
        FileUploadComponent
    },
    methods:{
        async getAllPreferenceSettings(filter = null){
            this.initialSettings()
            this.loaded = false;
            this.hasUploaded = false;
            this.hasUploadedLanding = false;
            this.hasUploadedLandings = false;
            let conditions = ["-", ":"]
            let payload = {
                type: 'Preferences',
                url: 'moduleSettings',
                event_id: this.GET_EVENT_ID
            };
            await this.$store.dispatch("urlGet", payload).then(async (res)=>{
                this.currentSettings = res
                await Promise.all(res.map(async (e) => {
                    await Promise.all(Object.keys(this.form).filter(async (el) => {
                        if (el == e.parameter) {
                            if(e.value !== null){
                                if (conditions.some((el) => e.value.includes(el))) {
                                this.form[el] = e.value;
                                } else {
                                this.form[el] = e.value;
                                }
                            }
                        }
                    }));
                }));
                this.isMounted = true;
            })
        },
        getFilters(){
            let filters = {
                data: this.form,
                type:'Preferences'
            }
            this.$store.commit('FORM_SETTINGS',filters);
        },
        checkEndNomination(){
            let payload = {
                url: 'checkSettingsByParameter',
                parameter: 'nomination_end_date',
                event_id: this.event_id
            }
            this.$store.dispatch('urlPost', payload).then((res)=>{
                if(res == 0){
                    this.votingIsDisabled = true;
                }else{
                    this.votingIsDisabled = false;
                }
            })
        },
        uploadedData(data) {
            if (!data) {
                this.form.system_toolbar_image = null;
            }
            this.form[data.obj_key] = data.attachments;
            this.preview[data.previewKey] = data.attachments[0]
            switch(data.obj_key){
                case 'system_toolbar_image':
                    this.hasUploaded =  true;
                    break;
                case 'system_landing_image':
                    this.hasUploadedLanding = true;
                    break;
                case 'system_app_image':
                    this.hasupload = true;
                    break;
                case 'system_nominee_bg_image':
                    this.hasuploads = true;
                    break;
                case 'system_raffle_image':
                    this.hasuploadss = true;
                    break;
                case 'system_winner_bg':
                    this.hasuploadsss = true;
                    break;
                case 'system_ticket_image':
                    this.hasuploadssss = true;
                    break;
                case 'system_suspense_image':
                    this.husuploadsssss = true;
                    break;
                case 'system_lazy_image':
                    this.hasUploadedLandings = true;
                    break;
            }
        },
        clearFileUpload(confirm) {
            if (confirm) {
                this.form.system_toolbar_image = null;
            }
            this.form.system_toolbar_image = null;
            this.$refs.fileUpload.reset();
            this.checkSavedToolbarImage()
        },
        checkSavedToolbarImage(){
            let payload = {
                url: 'checkSettingsByParameter',
                parameter: 'system_toolbar_image',
                event_id: this.GET_EVENT_ID
            }
            this.$store.dispatch('urlPost', payload).then((res)=>{
                this.form.system_toolbar_image = res
            })
            this.hasUploaded = false
        },
        initialSettings(){
            this.form = {
                system_toolbar_color: '',
                system_toolbar_font_color_main: '',
                border_dotted_log_out: '',
                system_font_color_secondary_log_out: '',
                system_font_family_primary: '',
                system_admin_font_family_primary:'',
                system_yourapp_font_style:'',
                system_toolbar_icon_color: '',
                system_main_label_color: '',
                system_secondary_label_color: '',
                system_guest_information_color: '',
                log_in_button_text_color: '',
                icon_color_main: '',
                submit_button_text_color: '',
                window_action_button_color: '',
                window_action_button_text_color: '',
                window_carousel_navigate_button_color: '',
                system_body_color: '',
                expansion_title_color: '',
                system_dialog_border: '',
                window_carousel_navigate_text_color: '',
                system_toolbar_image: null,
                system_landing_image: null,
                system_lazy_image: null,
                system_app_image:null,
                system_nominee_bg_image:null,
                system_raffle_image:null,
                system_winner_bg:null,
                system_ticket_image:null,
                system_suspense_image:null,
                swal_border: '',
                swal_color: '',
                swal_background_color: '',
                swal_icon_color: '',
                system_main_background_color: '',
                system_expansion_color: '',
                system_expansion_background:'',
                system_admin_toolbar_color:'',
                system_admin_font_toolbar_color:'',
                system_admin_icon_toolbar_color:'',
                system_admin_background_color:'',
                system_sub_admin_toolbar_color:'',
                system_admin_table_color:'',
                system_totalpax_card_color:'',
                system_totalattended_card_color:'',
                system_notattended_card_color:'',
                system_totalpercent_card_color:'',
                system_totalcompany_card_color:'',
                system_sub_font_toolbar_color:'',
                system_admin_button_color:'',
                system_text_button_color:'',
                system_tableheader_color:'',
                system_textdata_color:'',
                system_dialog_color:'',
                system_dialog_text_color:'',
                system_dialog_button_color:'',
                system_dialog_font_color:'',
                system_raffle_button:'',
                system_raffle_confetti_color:'',
                system_raffle_confetti_color_two:'',
                system_raffle_winner_text_color:'',
                system_raffle_winner_text_color_two:'',
                system_raffle_suspense_card_color:'',
                system_raffle_suspense_card_color_two:'',
                system_raffle_suspense_card_color_three:'',
                system_redirect_button_color:'',

            }
        }
    },
    watch:{
        // GET_FORM_SETTINGS:{
        //     handler(val){
        //         this.items = val

        //         val.forEach(e=>{
        //             Object.keys(this.form).filter(el=>{
        //                 if(el == e.parameter){
        //                     this.form[el] = parseFloat(e.value)
        //                 }
        //             });
        //         })
        //     }
        // },
        form:{
            handler(val){
                this.getFilters()
            },
            deep:true
        },
        'form.system_toolbar_image':{
            handler(val){
                this.preview.toolbar_image = val;
            }
        },
        'form.system_landing_image':{
            handler(val){
                this.preview.landing_image = val;
            }
        },
        'form.system_lazy_image':{
            handler(val){
                this.preview.lazy_image = val;
            }
        },
        'form.system_app_image':{
            handler(val){
                this.preview.app_image=val;
            }
        },
        'form.system_nominee_bg_image':{
            handler(val){
                this.preview.nominee_bg_image=val;
            }
        },
        'form.system_raffle_image':{
            handler(val){
                this.preview.raffle_image=val;
            }
        },
        'form.system_winner_bg':{
            handler(val){
                this.preview.winner_bg=val;
            }
        },
        'form.system_ticket_image':{
            handler(val){
                this.preview.ticket_image=val;
            }
        },
        'form.system_suspense_image':{
            handler(val){
                this.preview.suspense_image=val;
            }
        }
        },
        GET_NEW_DATA:{
            handler(val){
                if(val){
                    this.getAllPreferenceSettings();
                    this.$store.commit('NEW_DATA', false);
                }
            }
        },
        GET_EVENT_ID:{
            async handler(val){
                if(val){
                    await this.getAllPreferenceSettings();
                }
            }
        }
    }   

</script>   
<style scoped>

.v-tabs-slider{
    background:linear-gradient(#da7202, #f6ff00)!important;
}
.v-breadcrumbs__item{   
    cursor: pointer;
}

.selected {
    opacity: 100%;
}
h4 ,h1 , h5, h2 ,h3{
        font-family: Evogria;
        background: -webkit-linear-gradient(left,#da7202, #f6ff00);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

.non-selected {
    opacity: 40%;
}
.v-breadcrumbs__item{
    cursor: pointer;
}
.custom-tabs {
    background-color: transparent !important;
}

.custom-tabs .v-tab {
    background-color: transparent !important;
}
.container {

  position: relative;
  width: 100%;
  color:#1e1e1e
}

.fixed-tabs-container {
  position: sticky;
  top: 100px;   
  height:10px;
  background: #1e1e1e; /* Adjust background color */
  z-index: 1000; /* Ensure it’s above other content */
}

.fixed-tabs {
  background: #1e1e1e; /* Adjust background color */
}

.content-card {
  margin-top: 56px; /* Adjust based on the height of the tabs */
  width: 100%;
  background-color: #1e1e1e;
}

.scrollable-content {
  max-height: calc(70vh - 145px); /* Adjust based on the height of the tabs */
  overflow-y: auto;
}


</style>
