<template>
    <v-form ref="form" lazy-validation >
        <v-card class="overflow-x-hidden system_dialog_color system_admin_font_family_primary">
            <v-card-title class="pa-3 pl-3 pb-0 border-bottom system_dialog_color">
                <span class="h5 system_dialog_font_color">User Information</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeDialog()"
                        >
                            <span class="system_dialog_font_color"><v-icon>mdi-close-circle</v-icon></span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-card-text class="system_dialog_color">
                <v-row no-gutters>
                    <v-col cols="6" class="px-3" >
                        <v-text-field
                            v-model="form.name"
                            :disabled="disabled_view"
                            label="Name"
                            class="system_dialog_text_color"
                            outlined
        
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="px-3">
                        <v-text-field
                            v-model="form.username"
                            :disabled="disabled_view"
                            label="Username"
                            class="system_dialog_text_color"
                            outlined
                          
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="6" class="px-3" v-if="actions !== 'View' && actions !== 'Update'">
                        <v-text-field
                            v-model="form.password"
                            :disabled="disabled_view"
                            label="Password"
                            class="system_dialog_text_color"
                            type="password"
                            outlined
                       
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="6" class="px-3" v-if="actions !== 'View' && actions !== 'Update'">
                        <v-text-field
                            v-model="form.password_confirmation"
                            :disabled="disabled_view"
                            :rules="errors.required"
                            label="Confirm Password"
                            class="system_dialog_text_color"
                            type="password"
                            outlined
                         
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="px-3 -3">
                        <v-switch
                            v-model="form.super_admin"
                            :disabled="disabled_view"
                            label="Super Admin"
                            class="system_dialog_text_color"
                            outlined
                            dense
                        ></v-switch>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="text-center system_dialog_color" style="border-top: 1px solid #262626">
                <v-col cols="12" class="ml-5" v-if="actions != 'View'">
                    <v-btn
                        @click="actions === 'Submit' ? addUserDetails() : updateUserDetails()"
                        :disabled="disabled_view"
                        class="system_dialog_button_color"
                    >
                        <span class="system_dialog_font_color">{{actions}}</span>
                    </v-btn>
                </v-col>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            valid: false,
            overlay: false,
            disabled_view: false,
            errors: {
                required: [(v) => !!v || "Field is required"],
                number: [
                    (v) => !!v || "Cost is required",
                    (v) => !isNaN(v) || "Input must be a number",
                ],
            },
            form: {
                name: '',
                username: '',
                password: '',
                confirm_password: '',
                super_admin: false,
            },
            isHover: true,
        };
    },
    computed: {
        ...mapGetters([
            "ACTION",
            "DIALOGS",
            "GET_DIALOG_VIEW",
        ]),
        actions() {
            if (this.ACTION === "Submit") {
                this.disabled_view = false;
                return "Submit";
            } else if (this.ACTION === "Update") {
                this.disabled_view = false;
                this.getUserDetails();
                return "Update";
            } else if (this.ACTION === "View") {
                this.disabled_view = true;
                this.getUserDetails();
                return "View";
            }   
        },
    },
    created() {
        this.initialForm();
    },
    mounted() {
        
    },
    methods: {
        initialForm() {
        this.form = {
            id:'',
            name: '',
            username: '',
            password: '',
            password_confirmation: '', 
            super_admin: false,
        };
        if (this.$refs.form) {
            this.$refs.form.resetValidation(); 
        }
    },

    closeDialog() {
        this.$store.commit("DIALOG", false);
    },

    async addUserDetails() {
        this.$refs.form.validate();

        let name_swal_text = this.form.name ? '' : 'Name';
        let username_swal_text = this.form.username ? '' : 'Username';
        let password_swal_text = this.form.password ? '' : 'Password';
        let confirm_password_swal_text = this.form.password_confirmation ? '' : 'Confirm Password';
        let array_text = [username_swal_text, name_swal_text, password_swal_text, confirm_password_swal_text];
        let filtered = array_text.filter(el => el !== '');

        if (this.form.name && this.form.username && this.form.password && this.form.password_confirmation && this.form.super_admin !== undefined) {
            if (this.form.password !== this.form.password_confirmation) {
                Swal.fire({
                    title: 'Error!',
                    text: 'Passwords do not match.',
                    icon: 'error',
                    allowOutsideClick: false,
                });
                return;
            }
            let payload = {
                url: "user_detail",
                name: this.form.name,
                username: this.form.username,
                password: this.form.password,
                password_confirmation: this.form.password_confirmation, 
                super_admin: this.form.super_admin ? 1 : 0 
            };

            try {
                let response = await this.$store.dispatch('urlPost', payload);
                Swal.fire(response.icon.toUpperCase(), response.message, response.icon);
                if (response.icon === 'success') {
                    this.$store.commit('NEW_MASTER_DATA', true);
                    this.$store.commit("DIALOG", false);
                    this.closeDialog();
                }
            } catch (error) {
                if (error.response && error.response.status === 422) {
                    let errorMessage = error.response.data.errors.password
                        ? error.response.data.errors.password[0]
                        : 'Validation Error';
                    Swal.fire({
                        title: 'Error!',
                        text: errorMessage,
                        icon: 'error',
                        allowOutsideClick: false,
                    });
                    console.log(errorMessage);
                } else if (error.response && error.response.status === 401) {
                    Swal.fire({
                        title: 'Unauthenticated!',
                        text: '',
                        icon: 'error',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem("token");
                            window.location.reload();
                            this.$router.push('/');
                        }
                    });
                } else {
                    Swal.fire({
                        title: 'Saving Failed!',
                        text: 'Your username is already Taken!',
                        icon: 'error',
                        allowOutsideClick: false
                    });
                }
                console.log(error);
            }
        } else {
            Swal.fire({
                text: `Fill Required Fields (${filtered.join(', ')})`,
                icon: 'warning',
                allowOutsideClick: false,
            });
        }
    },

    async updateUserDetails() {
    try {
        let payload = {
            url: "user_detail",
            id: this.form.id,
            name: this.form.name,
            username: this.form.username,
            super_admin: this.form.super_admin
        };

         

        const response = await this.$store.dispatch("urlPut", payload);
       

        Swal.fire(response.icon.toUpperCase(), response.message, response.icon);
        this.$store.commit('NEW_MASTER_DATA', true);
        this.closeDialog();
    } catch (error) {
        Swal.fire('Error', 'Update failed. Please try asdasdaagain.', 'error');
        console.error("Update failed:", error);
    }
    },

        async getUserDetails() {
            this.initialForm();
            this.form.id                        =this.GET_DIALOG_VIEW.id;
            this.form.name                    = this.GET_DIALOG_VIEW.name;
            this.form.username                 = this.GET_DIALOG_VIEW.username;
            this.form.password                  =this.GET_DIALOG_VIEW.password;
            this.form.super_admin                  = this.GET_DIALOG_VIEW.super_admin;
           
        },

        
    },
    watch: {
        DIALOGS: {
            handler(val) {
                if (!val) {
                    this.initialForm();
                    this.$store.commit('DIALOG_VIEW', {});
                }
            },
        },
    },
};
</script>


<style scoped>
    .modal_title {
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: white;
    }
</style>
