<template>
    <span>
        <v-dialog
            v-model="GET_FILE_VIEWER_DIALOG"
            width="50%"
            persistent
        >
            <v-card class="overflow-x-hidden">
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline system_dialog_font_color">Activity Images</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right system_dialog_button_color" @click="triggerDialog(false)">
                            <span class="system_dialog_font_color"><v-icon>mdi-close</v-icon></span>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="my-0" />
                <v-card-text>
                    <!-- <v-row class="mt-4">
                        <v-col cols="4">
                            <v-text-field
                                v-model="identifier.value"
                                :label="identifier.key"
                                readonly
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row> -->
                    <v-row class="mt-4">
                        <v-col>
                            <v-data-table
                                :headers="fileViewer.datatable.headers"
                                :items="fileViewer.datatable.items"
                                :items-per-page="7"
                                :footer-props="{
                                    itemsPerPageOptions: fileViewer.datatable.itemsPerPageOptions
                                }"
                                disable-sort
                            >
                            <template v-slot:[`header.event_activity_image`]="{ header }">
							    <span class="system_tableheader_color">{{ header.text }}</span>
                            </template>
                            <template v-slot:[`header.name`]="{ header }">
                                <span class="system_tableheader_color">{{ header.text }}</span>
                            </template>
                            <template v-slot:[`header.created_at`]="{ header }">
                                <span class="system_tableheader_color">{{ header.text }}</span>
                            </template>
                            <template v-slot:[`header.action`]="{ header }">
                                <span class="system_tableheader_color">{{ header.text }}</span>
                            </template>
                                <template v-slot:[`item.event_activity_image`]="{item}">
								    <v-img class="img-thumbnail" width="50" height="50" :src="item.event_activity_image" eager contain></v-img>
                                </template>
                                <template v-slot:[`item.action`]="{item, index}">
								    <span class="text-nonwrap">
                                        <v-btn text icon color="#17468f">
                                            <v-icon class="btn-action" small @click="showFile(item);">mdi-eye</v-icon>
                                        </v-btn>
                                        <v-btn text icon color="red">
                                            <v-icon class="btn-action" small @click="deleteFile(item.id, index);">mdi-delete</v-icon>
                                        </v-btn>
                                    </span>
                                </template>
                                <template v-slot:[`item.name`]="{ item }">
								    <span class="system_textdata_color">{{ item.name }}</span>
                                </template>
							<template v-slot:[`item.action`]="{item}"></template>
                                <template v-slot:[`item.created_at`]="{item}">
                                    <span class="system_textdata_color">{{ formatDate(item.created_at) }}</span>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="text-center">
                    <!-- <v-row class="ma-1">
                        <v-col cols="12">
                            <v-btn
                            class="float-center"
                            @click="updateEmployeeOvertimeNow()"
                            >
                            <span>Submit</span>
                            </v-btn>
                        </v-col>
                    </v-row> -->
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="viewFile.dialog" persistent max-width="80%">
            <v-card class="overflow-x-hidden">
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">{{ viewFile.name }}</span>
                        </v-card-title>
                    </v-col>

                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeViewFile()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="my-0" />

                <div class="iframe-container d-flex justify-center align-center">
                    <v-img :src="viewFile.sourceFrame" contain height="500" class="responsive-iframe"/>
                </div>
            </v-card>
        </v-dialog>
    </span>
</template>
<script>
import SharedFunctionsComponentVue from "@/components/shared/SharedFunctionsComponent.vue";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import moment from 'moment';

export default {
    mixins: [SharedFunctionsComponentVue],
    props: {
    
    },
    data() {
        return {
            valid: false,
            overlay: false,
            disabled_view: false,
            errors: {
                required: [(v) => !!v || "Field is required"],
                number: [
                    (v) => !!v || "Field is required",
                    (v) => !isNaN(v) || "Input must be a number",
                ],
            },
            form: {},
            userAccess: [],

            changeOTHours:{
                dialog: false
            },

            fileViewer: {
                datatable:{
                    headers: [
                        { text: 'Image', align: 'start', value: 'event_activity_image' },
                        { text: 'Name', align: 'start', value: 'name' },
                        { text: 'Created', align: 'end', value: 'created_at',width: '20%' },
                        { text: 'Action', align: 'start', value: 'action', width: '15%' },
                    ],
                    items: [],
                    itemsPerPageOptions: [5, 10, 15, 50, 100],
                }
            },
            viewFile: {
                dialog: false,
                name: '',
                sourceFrame: ''
            },
        };
    },
    computed: {
        ...mapGetters([
            "ACTION",
            "GET_FILE_VIEWER_DIALOG",
            "GET_FILE_VIEWER"
        ]),

    },
    created() {
        this.initialForm();
    },
    components:{
    },
    async mounted() {
    },
    methods: {
        formatDate(date){
            return moment(date).format('YYYY-MM-DD HH:mm:ss')
        },
        initialForm(){
            this.form = {
                reason: ''
            };
        },
        closeDialog(){
            this.$store.commit('HR_CHANGES_DIALOG', false);
        },
        triggerDialog(value){
            this.$store.commit('FILE_VIEWER_DIALOG', value)
        },
        showFile(item){
            this.viewFile.dialog = true;
            this.viewFile.name = item.name;
            this.viewFile.sourceFrame = item.event_activity_image;
        },
        closeViewFile(){
            this.viewFile.dialog = false;
        },
        deleteFile(id, index){
            Swal.fire({
                title: "Delete",
                text: `Are you sure you want to delete ?`,
                icon: 'question',
                showConfirmButton:true,
                confirmButtonText:'Confirm',
                showCancelButton:true,
                showCloseButton:true,
                confirmButtonColor: '397373',
                cancelButtonColor:'grey',
                reverseButtons:true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    let payload = {
                        url: 'deleteFile',
                        id: id
                    }
                    this.$store.dispatch('urlPost', payload).then((res)=>{
                        Swal.fire(
                            res.message,
                            "",
                            res.icon
                        )
                        this.fileViewer.datatable.items.splice(index, 1)
                    });
                }
            });
        }
    },
    watch: {
        USER_ACCESS:{
            handler(val){
                this.userAccess = val.map(e=>e.actions_code);
            }
        },
        GET_FILE_VIEWER:{
            handler(val){
                this.fileViewer.datatable.items = val;
            }
        }
    },
};
</script>
<style scoped>
.modal_title {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
}
</style>
