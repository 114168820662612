<template>
    <v-form ref="form" lazy-validation>
        <v-card class="overflow-x-hidden system_dialog_color system_admin_font_family_primary">
            <v-card-title class="pa-3 pl-3 pb-0 border-bottom system_dialog_color">
                <span class="h5 system_dialog_font_color">Event Information</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeDialog()"
                        >
                            <span class="system_dialog_font_color"><v-icon>mdi-close-circle</v-icon></span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-card-text class="system_dialog_color">
                
                <v-row no-gutters>
                    <v-col cols="12" class="px-10">
                        <v-text-field
                            v-model="form.code"
                            :disabled="disabled_view"
                            :rules="errors.required"
                            label="Code"
                            class="system_dialog_text_color"
                            outlined                        
                            required    
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" class="px-10">
                        <v-text-field
                            v-model="form.name"
                            :disabled="disabled_view"
                            :rules="errors.required"
                            label="Name"
                            class="system_dialog_text_color"
                            outlined
                            required
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" class="px-10">
                        <v-text-field
                            v-model="form.link"
                            :disabled="disabled_view"
                            :rules="errors.required"
                            label="Link"
                            class="system_dialog_text_color"
                            outlined
                            required
                            dense
                            
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions  class="text-center system_dialog_color" style="border-top: 1px solid #262626">
                <v-col cols="12" class="ml-5" v-if="actions != 'View'">
                    <v-btn
                    @click="actions == 'Submit' ? addEventDetails() : updateEventDetails()"
                    :disabled = "disabled_view"
                    class="system_dialog_button_color"
                    >
                        <span class="system_dialog_font_color">{{actions}}</span>
                    </v-btn>
                </v-col>

            </v-card-actions>
        </v-card>
    </v-form>
    
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            valid: false,
            overlay: false,
            disabled_view: false,
            errors: {
                required: [(v) => !!v || "Field is required"],
                number: [
                    (v) => !!v || "Cost is required",
                    (v) => !isNaN(v) || "Input must be a number",
                ],
            },
            form: {},
            isHover: true
        };
    },
    computed: {
        ...mapGetters([
            "ACTION",
            "DIALOGS",
            "GET_DIALOG_VIEW",
            "GET_EVENT_ID"
        ]),
        actions() {
            if (this.ACTION == "Submit") {
                this.disabled_view = false;
                this.isViewBtn = true;
                return "Submit";
            } else if (this.ACTION == "Update") {
                this.disabled_view = false;
                this.isViewBtn = true;
                this.getEventDetails();
                return "Update";
            } else if (this.ACTION == "View") {
                this.disabled_view = true;
                this.isViewBtn = true;
                this.getEventDetails();
                return "View";
            }
        },
    },
    created() {
        this.initialForm();
    },
    mounted() {
    },
    methods: {
        
        initialForm() {
            this.form = {
                code: '',
                name: '',
                link: '',
                active: true,
            
            };
            if (this.$refs.form) {
                this.$refs.form.resetValidation(); 
            }
            
        },

        closeDialog() {
            this.$store.commit("DIALOG", false);
            
        },
        async addEventDetails() {
            
            this.$refs.form.validate();

            let code_swal_text = this.form.code ? '' : 'Code';
            let name_swal_text = this.form.name ? '' : 'Name';
            let link_swal_text = this.form.link ? '' : 'Link';
            let array_text = [code_swal_text, name_swal_text, link_swal_text];
            let filtered = array_text.filter(function (el) {
                return el.replace(',', '');
            });

            if (this.form.code && this.form.name && this.form.link) {
                let payload = {
                    url: "event_detail",
                    ...this.form,
                };

                try {
                    let response = await this.$store.dispatch('urlPost', payload);
                    Swal.fire(response.icon.toUpperCase(), response.message, response.icon);   
                    if(response.icon == 'success'){
                        this.$store.commit('NEW_MASTER_DATA', true);
                        this.$store.commit("DIALOG", false);
                        this.closeDialog();
                    }
                } catch (error) {
                    if (error.response && error.response.status === 422) {
                        let errorMessage = error.response.data.errors.link 
                            ? error.response.data.errors.link[0] 
                            : 'Validation Error';
                        Swal.fire({
                            title: 'Error!',
                            text: errorMessage,
                            icon: 'error',
                            allowOutsideClick: false,
                        });
                        console.log(errorMessage)
                    } else if (error.response && error.response.status === 401) {
                        Swal.fire({
                            title: 'Unauthenticated!',
                            text: '',
                            icon: 'error',
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.isConfirmed) {
                                localStorage.removeItem("token");
                                window.location.reload();
                                this.$router.push('/');
                            }
                        });
                    } else {
                        Swal.fire({
                            title: 'Saving Failed!',
                            text: 'An unexpected error occurred.',
                            icon: 'error',
                            allowOutsideClick: false
                        });
                    }
                    console.log(error)
                }
            } else {
                Swal.fire({
                    text: `Fill Required Fields (${filtered})`,
                    icon: 'warning',
                    allowOutsideClick: false,
                });
            }
            
        },
        
        
        async updateEventDetails() {
            
            let payload = {
                url: "event_detail",
                id: this.form.id,
                ...this.form
            }
            await this.$store.dispatch("urlPut", payload).then((response) => {
				Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                this.$store.commit('NEW_MASTER_DATA', true)
				this.closeDialog();
			});
        },
        async getEventDetails() {
            this.initialForm();
            this.form.id                    = this.GET_DIALOG_VIEW.id;
            this.form.code                  = this.GET_DIALOG_VIEW.code;
            this.form.name                  = this.GET_DIALOG_VIEW.name;
            this.form.link                  = this.GET_DIALOG_VIEW.link;
            this.form.active                = this.GET_DIALOG_VIEW.active;
        },
    
        
    },
    watch: {
        DIALOGS: {
            handler(val) {
                if (!val) {
                    this.initialForm();
                    this.$store.commit('DIALOG_VIEW', {})
                }else{
                    if(this.ACTION === 'Submit'){
                        
                    }
                }
            },
        },
    },
};
</script>
<style scoped>
    .modal_title {
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: white;
    }
</style>
