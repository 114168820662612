<template>
  <v-container fluid>
    <div class="d-flex justify-content-center" style="height: auto;">
      <v-dialog max-width="1000" v-model="eventFormDialog" persistent>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="#17468f" icon rounded
            fab plain v-bind="attrs" v-on="on">
            <v-icon size="40" left>
              mdi-draw-pen
            </v-icon>
            <span class="underline-animation" style="font-family: Evogria; font-size: 1.4rem; font-weight: bold;">
                Fill-up Event Form
            </span>
          </v-btn>
        </template>
        </v-dialog>
    </div>
    <template>
      <div class="pa-6">
      <v-img 
        src="@/assets/ingco_promo_landingv3.jpg"
        alt="Placeholder"
      ></v-img>
    </div>
    </template>
    
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header color="black" class="underline-animation-silver">      
          <v-btn color="silver" icon rounded x-small
          fab plain v-bind="attrs" v-on="on">
          <span style="font-family: Evogria; font-size: 1rem; font-weight: bold;">
              About the Event
          </span>
        </v-btn>
      </v-expansion-panel-header>
        <v-expansion-panel-content color="black">
            <div class="d-flex justify-content-center pa-4">
              <v-carousel hide-delimiters id="carousel" height="auto" style="width: 100%;" v-model="currentItem">
                <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src" cover></v-carousel-item>
              </v-carousel>
            </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="d-flex justify-content-center pa-8" style="height: auto;">
      <v-dialog max-width="1000" v-model="eventFormDialog" persistent>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="#17468f" icon rounded
            fab plain v-bind="attrs" v-on="on">
            <v-icon size="40" left>
              mdi-draw-pen
            </v-icon>
            <span class="underline-animation" style="font-family: Evogria; font-size: 1.4rem; font-weight: bold;">
                Fill-up Event Form
            </span>
          </v-btn>
        </template>
<v-card class="dotted-border-orange" style="font-family: Evogria;">
  <v-card-title class="justify-content-center">
    <div class="d-flex justify-content-between">
      <span>Event Form</span>
      <v-btn @click="closeEventForm" plain text icon color="red" class="align-start" style="left: 420px;">
        <v-icon class="btn-action">mdi-window-close</v-icon>
      </v-btn>
    </div>
  </v-card-title>
  <v-card-text>
    <div class="flex-container">
      <span>Employee ID:</span>
      <v-text-field
      @keyup.enter="submitCredentials"
        color="#17468f"
        clearable
        :items="employeeDatas"
        item-text="employee_id"
        item-value="employee_id"
        v-model="employeeId">
      </v-text-field>
    </div>
  </v-card-text>
  <v-divider :thickness="20"></v-divider>
  <v-card-text>
    <div class="flex-container">
      <span>Name:</span>
      <v-text-field disabled v-model="selectedEmployeeName" color="#17468f"></v-text-field>
    </div>
  </v-card-text>
  <v-card-text>
    <div class="flex-container">
      <span>Department:</span>
      <v-text-field disabled v-model="selectedEmployeeDepartment" color="#17468f"></v-text-field>
    </div>
  </v-card-text>

  <v-divider :thickness="20"></v-divider>
  <div class="d-flex justify-content-center">
    <v-btn @click="submitCredentials" color="#17468f" plain dark x-large>
      Submit
    </v-btn>
  </div>
</v-card>
        </v-dialog>
    </div>
  </v-container>
</template>
  
<script>
  import Swal from 'sweetalert2';
  import axios from 'axios';
  export default {
    data() {
      return {
        last_name: '',
        first_name: '',
        middle_name: '',
        employeeDatas: [],
        eventFormDialog: false,
        deptID: null,
        localID: null,
        employeeId: null,
        department: '',
        data: [],
        items: [
          {
            src: require('@/assets/ingcoph_logo.jpg'),
          },
          {
            src: require('@/assets/ingcoph_logo.jpg'),
          },
        ],
        currentItem: 1, 
      };
    },
    computed: {
      selectedEmployee() {
        return this.employeeDatas.find(employee => employee.employee_id === this.employeeId) || { full_name: '', department_name: '', employee_data_local_id: '', department_id: ''};
      },
      selectedEmployeeName() {
        return this.selectedEmployee.full_name;
      },
      selectedEmployeeDepartment() {
        this.localID = this.selectedEmployee.employee_data_local_id;
        this.deptID = this.selectedEmployee.department_id;
        return this.selectedEmployee.department_name;
      }
    },
    created() {
      document.title = 'INGCO Night';
      this.startCarouselAutoAdvance();
      this.fetchData();
    },
    beforeDestroy() {
      this.stopCarouselAutoAdvance();
    },
    methods: {
      closeEventForm() {
  this.employeeId = null;
        this.eventFormDialog = !this.eventFormDialog;
        },
      async fetchData() {
        try {
          let response = await axios.get(process.env.VUE_APP_API_HOST + '/api/submit-credentials');
          this.employeeDatas = response.data.allEmployeeData;
        } catch (error) {
          console.error(error);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Failed to fetch employee data!',
          });
        }
      },
      async submitCredentials() {
  Swal.fire({
    title: 'Submitting',
    allowOutsideClick: false,
    showCancelButton: false,
    showConfirmButton: false,
  });
  if (!this.employeeId) {
    Swal.fire({
      icon: 'error',
      title: 'Please Input',
      allowOutsideClick: false,
      text: 'Please enter an Employee ID.',
      customClass: {
        confirmButton: 'orange-button',
      }
    });
    return;
  }
  if (this.employeeId.length < 9 || this.employeeId.length > 9) {
    Swal.fire({
      icon: 'error',
      title: 'Invalid Employee ID',
      allowOutsideClick: false,
      text: 'Please check your Employee ID',
      customClass: {
        confirmButton: 'orange-button',
      }
    });
    return;
  }
  Swal.showLoading();
  this.data.push({
    employee_data_id: this.localID,
    department_id: this.deptID,
  });
  try {
    let response = await axios.post(process.env.VUE_APP_API_HOST + '/api/selected-id', this.data);
    if (response.data.message === "Employee Already Listed!") {
      Swal.fire({
        title: 'Employee Information',
        text: response.data.message,
        icon: 'info',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'orange-button',
        }
      });
    } else {
      setTimeout(() => {
  Swal.fire({
    title: 'Success!',
    html: response.data.message,
    icon: 'success',
    allowOutsideClick: false,
    confirmButtonText: "See Event Information",
    customClass: {
      confirmButton: 'orange-button',
    }
  }).then((result) => {
    if (result.isConfirmed) {
      this.eventFormDialog = false;
      Swal.showLoading();
      setTimeout(() => {
        this.$router.push('/home'); 
      }); 
    }
  });
}, 2000); 
    }
  } catch (error) {
    if (error.response.status === 500) {
        Swal.fire({
            title: "<strong><u>NOT FOUND</u></strong>",
            text: error.response.data.message, 
            icon: 'warning',
            allowOutsideClick: false,
            customClass: {
        confirmButton: 'orange-button',
        } });
        } 
  }
  this.data = [];
  this.employeeId = null;
  to="/home";
},

      startCarouselAutoAdvance() {
        this.carouselTimer = setInterval(() => {
          this.currentItem = (this.currentItem + 1) % this.items.length;
        }, 10000); // Advance every 5 seconds (5000 milliseconds)
      },
      stopCarouselAutoAdvance() {
        clearInterval(this.carouselTimer);
      },
      reset() {
        this.currentItem = 0;
      },
    },
  };
  </script>
  
  <style scoped>
  .flex-container {
    display: flex;
    align-items: center;
  }
  .dotted-border-orange {
    border: 1px dotted rgb(255, 255, 255);
  }
  .flex-container span {
    margin-right: 10px; 
  }
  </style>
