<template>
    <v-form ref="form" v-model="valid"  style="height:875px;">
        <v-card class="overflow-x-hidden system_dialog_color system_admin_font_family_primary pt-0">
            <v-card-title class="pa-3 pl-3 pb-0 border-bottom system_dialog_color">
                <span class="h5 system_dialog_font_color">Event Nominee Details</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeDialog()"
                        >
                            <span class="system_dialog_font_color"><v-icon>mdi-close-circle</v-icon></span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text class="system_dialog_color">
                <v-container class="pb-0 mt-1" fluid>
                    <v-row no-gutters v-if="actions != 'Submit'">
                        <v-col cols="12" class="pa-0 pb-2" >
                            <v-carousel cycle interval="10000" :show-arrows="!['xs', 'sm'].includes($vuetify.breakpoint.name)" hide-delimiters id="carousel" height="auto">
                                <v-carousel-item v-for="(item, i) in form.event_nominee_images" :key="i" style="">
                                    <v-card tile>
                                        <v-hover v-model="isHover">
                                            <template v-slot="{ hover }">
                                                <v-card>
                                                    <v-container fluid class="picture">
                                                        <v-img 
                                                            :src="item.event_nominee_image"
                                                            :style="item.main_image == 1 ? 'border: #2590ff 3px solid;' : ''" 
                                                            position="top"
                                                            class="ma-0 white--text align-end"
                                                            height="400"
                                                            contain
                                                        >   
                                                        </v-img>
                                                    </v-container>
                                                    <v-fade-transition style="opacity:0.5;">
                                                        <v-overlay absolute :color="hover ? 'white' : ''"  class="d-flex flex-row justify-content-center align-items-center">
                                                                <v-row v-if="hover == true">
                                                                    <v-tooltip bottom color="#2590ff">
                                                                        <template v-slot:activator="{ on, attrs }" >
                                                                            <v-btn
                                                                                @click="selectAsMainImage(item)"
                                                                                v-on="on"
                                                                                v-bind="attrs"
                                                                                :disabled="disabled_view"
                                                                                style="opacity:1; cursor: pointer;"
                                                                                class="ma-2"
                                                                                color="#2590ff"
                                                                                text
                                                                            >
                                                                                <span class="system_dialog_font_color"><v-icon x-large>mdi-image-check-outline</v-icon></span>
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>Mark as Main</span>
                                                                    </v-tooltip>
                                                                    <v-tooltip bottom color="#2590ff">
                                                                        <template v-slot:activator="{ on, attrs }" >
                                                                            <v-btn
                                                                                v-on="on"
                                                                                v-bind="attrs"
                                                                                :disabled="disabled_view"
                                                                                style="opacity:1; cursor: pointer;"
                                                                                class="ma-2"
                                                                                color="#2590ff"
                                                                                text
                                                                            >
                                                                                <label for="upload-file"><span class="upload-file system_dialog_font_color"><v-icon x-large>mdi-tray-arrow-up</v-icon></span></label>
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>Upload Image</span>
                                                                    </v-tooltip>
                                                                    <input  class="d-none" id="upload-file" type="file" ref="fileInput" @change="uploadImage" accept="image/png, image/jpeg"/>
                                                                </v-row>
                                                        </v-overlay>
                                                    </v-fade-transition>
                                                </v-card>
                                            </template>
                                        </v-hover>
                                    </v-card>
                                </v-carousel-item>
                            </v-carousel>  
                        </v-col>
                        <v-col cols="12" class="pa-0">
                            <v-text-field
                                v-model="form.full_name"
                                :disable="disabled_view"
                                label="Full Name"
                                class="system_dialog_text_color"
                                readonly
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                        
                        <v-col cols="12" class="pa-0">
                            <v-text-field
                                v-model="form.company_name"
                                :disable="disabled_view"
                                label="Company Name"
                                class="system_dialog_text_color"
                                readonly
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12" class="pa-0">
                            <v-text-field
                                v-model="form.nomination_count"
                                :disable="disabled_view"
                                label="Nomination Count"
                                readonly
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col> -->
                        <v-col cols="12" class="pa-0">
                            <v-text-field
                                v-model="form.vote_count"
                                :disable="disabled_view"
                                label="Vote Count"
                                class="system_dialog_text_color"
                                readonly
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pa-0">
                            <v-checkbox
                                v-model="form.voteable"
                                :readonly="disabled_view"
                                label="Voteable"
                                class="system_dialog_text_color"
                                :true-value="1"
                                :false-value="0"
                                outlined
                                hide-details=""
                                dense
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-else>
                        <v-col class="pa-0 pb-2" cols="12">
                            <v-hover v-model="isHover">
                                <template v-slot="{ hover }">
                                    <v-card>
                                        <v-container fluid class="picture">
                                            <v-img :src="form.nominee_image" contain aspect-ratio="1.7">
                                            </v-img>
                                        </v-container>
                                        <v-fade-transition style="opacity:0.5;">
                                            <v-overlay absolute :color="hover ? 'white' : ''"  class="d-flex flex-row justify-content-center align-items-center">
                                                    <v-row>
                                                        <v-tooltip bottom color="#2590ff">
                                                            <template v-slot:activator="{ on, attrs }" >
                                                                <v-btn
                                                                    v-show="hover == true"
                                                                    v-on="on"
                                                                    v-bind="attrs"
                                                                    :disabled="disabled_view"
                                                                    style="opacity:1; cursor: pointer;"
                                                                    class="ma-2"
                                                                    color="#2590ff"
                                                                    text
                                                                >
                                                                    <label for="upload-file"><span class="upload-file system_dialog_font_color"><v-icon x-large>mdi-tray-arrow-up</v-icon></span></label>
                                                                </v-btn>
                                                            </template>
                                                            <span class="system_dialog_font_color">Upload Image</span>
                                                        </v-tooltip>
                                                    </v-row>
                                                <input  class="d-none" id="upload-file" type="file" ref="fileInput" @change="uploadImageSubmit" accept="image/png, image/jpeg"/>
                                            </v-overlay>
                                        </v-fade-transition>
                                    </v-card>
                                </template>
                            </v-hover>
                        </v-col>

                        <v-col class="pa-0 pb-2" cols="12">
                            <v-autocomplete
                                v-model="form.company_id"
                                :items="dropdowns.company_list"
                                @change="filterGuests()"
                                label="Company"
                                item-text="name"
                                item-value="id"
                                class="system_dialog_text_color"
                                persistent-hint
                                hide-details
                                tile
                                outlined	
                                dense
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col class="pa-0 pb-2" cols="12">
                            <v-autocomplete
                                v-model="form.event_participant_id"
                                :items="dropdowns.guests"
                                @change="filterCompany()"
                                label="Guest Name"
                                item-text="full_name"
                                item-value="id"
                                class="system_dialog_text_color"
                                persistent-hint
                                hide-details
                                :rules="errors.required"
                                tile
                                outlined	
                                dense
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions
                class="text-center system_dialog_color"
                style="border-top: 1px solid #000000"
            >
                <v-row class="ma-1 pb-2">
                    <v-col cols="12" >
                        <v-btn
                            v-if="actions != 'View'"
                            @click="
                                actions == 'Submit'? addEventNominee() : updateEventNominee()"
                            :disabled="disabled_view"
                            class="system_dialog_button_color"
                        >
                            <span class="system_dialog_font_color">{{ actions }}</span>
                        </v-btn>
                        <span class="pr-12">&nbsp;</span>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-form>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";  
export default {
    data() {
        return {
            valid: false,
            overlay: false,
            disabled_view: false,
            errors: {
                required: [(v) => !!v || "Field is required"],
                number: [
                    (v) => !!v || "Cost is required",
                    (v) => !isNaN(v) || "Input must be a number",
                ],
            },
            form: {},
            isHover: true,
            dropdowns: {
                guests: [],
                guests_temp: [],
                company_list: [],
            }
        };
    },
    computed: {
        ...mapGetters([
            "ACTION",
            "DIALOGS",
            "GET_DIALOG_VIEW",
            "GET_GENDER"
        ]),
        actions() {
            if (this.ACTION == "Submit") {
                this.disabled_view = false;
                this.isViewBtn = false;
                return "Submit";
            } else if (this.ACTION == "Update") {
                this.disabled_view = false;
                this.isViewBtn = true;
                this.getNomineesData();
                return "Update";
            } else if (this.ACTION == "View") {
                this.disabled_view = true;
                this.isViewBtn = true;
                this.getNomineesData();
                return "View";
            }
        },
    },
    created() {
        this.initialForm();
    },
    mounted() {
        this.getCompanyList();
        this.getGuests();
        this.filterCompany()
    },
    methods: {
        initialForm() {
            this.form = {
                order      : 1,
                nominee_image : '',
                voteable: 0,
                company_id: null,
                event_participant_id: null,
                event_nominee_images: []
            };
            this.$refs.fileInput.value = null;
            this.isHover = true;
        },
        height(width) {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return width;
                case "lg":
                    return width;
                case "xl":
                    return width;
            }
        },
        closeDialog() {
            this.$store.commit("DIALOG", false);
        },
        async addEventNominee() {
            this.$refs.form.validate();
            let guest_swal_text = this.form.event_participant_id ? '' : 'Guest Name';
            let image_swal_text = this.form.nominee_image ? '' : 'Guest Image';
            let array_text =
            [
                guest_swal_text,
                image_swal_text,
            ]
            let filtered = array_text.filter(function (el) {
                return el.replace(',', '');
            })

            if (
                this.form.event_participant_id &&
                this.form.nominee_image
            ) {
                 let payload = {
                    url: "addNominee",
                    ...this.form
                }
                await this.$store.dispatch('urlPost', payload).then(response=>{
                    Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                    this.$store.commit('NEW_MASTER_DATA', true);
                    this.$store.commit("DIALOG", false);
                })
            } else {
                Swal.fire({
                    text: `Fill Required Fields (${filtered})`,
                    icon: 'warning',
                    allowOutsideClick: false,
                });
            }
           
        },
        async updateEventNominee() {
            delete this.form.event_nominee_images
            let payload = {
                url: "eventNominees",
                id: this.form.id,
                ...this.form
            }
            await this.$store.dispatch("urlPut", payload).then((response) => {
				Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                this.$store.commit('NEW_MASTER_DATA', true)
				this.closeDialog();
			});
        },
        async getNomineesData() {
            this.form.id                        = this.GET_DIALOG_VIEW.id;
            this.form.company_name              = this.GET_DIALOG_VIEW.company_name;
            this.form.full_name                 = this.GET_DIALOG_VIEW.full_name;
            this.form.nomination_count          = this.GET_DIALOG_VIEW.nomination_count;
            this.form.vote_count                = this.GET_DIALOG_VIEW.vote_count;
            this.form.event_participant_id      = this.GET_DIALOG_VIEW.event_participant_id;
            this.form.voteable                  = this.GET_DIALOG_VIEW.voteable;
            this.form.event_nominee_images      = this.GET_DIALOG_VIEW.event_nominee_images;
        },
        uploadImage(event) {
            Swal.fire({
                title: "",
                text: `Are you sure you want to upload this image ?`,
                icon: 'question',
                showConfirmButton:true,
                confirmButtonText:'Confirm',
                showCancelButton:true,
                showCloseButton:true,
                confirmButtonColor: '397373',
                cancelButtonColor:'grey',
                reverseButtons:true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    let that = this;
                    let reader = new FileReader()
                    reader.addEventListener('load', () => {
                        let payload = {
                            url: 'newNomineeImage',
                            event_participant_id: this.form.event_participant_id,
                            new_image: reader.result
                        }
                        this.$store.dispatch('urlPost', payload).then((response)=>{
                            this.form.event_nominee_images.unshift(response.new_data);
                            Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                        });
                    })
                    let file = event.target.files[0];
                    let url = reader.readAsDataURL(file);
                    
                }
            });
            
		},
        uploadImageSubmit(event) {
             let that = this;
			let reader = new FileReader()
			reader.addEventListener('load', () => {
				that.form.nominee_image = reader.result
			})
			let file = event.target.files[0];
			let url = reader.readAsDataURL(file);
		},
        selectAsMainImage(item){
            Swal.fire({
                title: "",
                text: `Are you sure you want to set this as main image ?`,
                icon: 'question',
                showConfirmButton:true,
                confirmButtonText:'Confirm',
                showCancelButton:true,
                showCloseButton:true,
                confirmButtonColor: '397373',
                cancelButtonColor:'grey',
                reverseButtons:true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.form.event_nominee_images.forEach(x=>{
                        x.main_image = 0;
                    });
                    let payload = {
                        ...item,
                        url: 'setMainImage',
                        event_participant_id: item.event_participant_id
                    }
                    this.$set(item, 'main_image', 1);
                    this.$store.dispatch('urlPost', payload).then((response)=>{
                        Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                    });
                }
            });
        },
        getGuests() {
            let payload = {
                url: 'getGuests'
            }
            this.$store.dispatch('urlGet', payload).then((res) => {
                let data = res.filter(x => x.gender == this.GET_GENDER);
                this.dropdowns.guests = data;
                this.dropdowns.guests_temp = data;
                this.getCompanyList();
            })
        },

        getCompanyList() {
            let payload = {
                url: 'companyRoute'
            }
            this.$store.dispatch('urlGet', payload).then((res) => {
                let companyIdsWithGuests = new Set(this.dropdowns.guests.map(g => g.company_id));
                let filteredCompanies = res.data.filter(company => companyIdsWithGuests.has(company.id));
                this.dropdowns.company_list = filteredCompanies;
            })
            
        },

        filterGuests() {
            if (this.form.company_id) {
                let filtered = this.dropdowns.guests_temp.filter(el => el.company_id == this.form.company_id);
                this.dropdowns.guests = filtered;
            } else {
                this.dropdowns.guests = this.dropdowns.guests_temp;
            }
        },

        filterCompany() {
            if (this.form.event_participant_id) {
                this.form.company_id = this.dropdowns.guests.find(x => x.id == this.form.event_participant_id)?.company_id ?? null;
            } else {
                this.form.company_id = null;
            }
        },
},
    watch: {
        DIALOGS: {
            handler(val) {
                if (!val) {
                    this.initialForm();
                    this.$store.commit('DIALOG_VIEW', {})
                }else{
                    if(this.ACTION == 'Submit'){
                        this.getGuests();
                    }
                }
            },
        },
        GET_GENDER: {
            handler(val){
            }
        }
    },
};
</script>
<style scoped>
    .modal_title {
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: white;
    }
</style>
