<template>
    <div class="w-500" > 
      <!-- Fixed Container for the Title -->
      <v-container
        fluid
        class="overflow-x-hidden overflow-hidden mt-1 fixed-title-container "
        style="background: #1e1e1e; z-index: 2;"
        height="800"
        color="orange"
      >
        <v-card class="pa-0 ma-0" height="100">
          <v-card-title class="fixed-title" height="100">
            <v-breadcrumbs :items="breadcrumbs">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  @click="changeActive(item)"
                  :style="active_bread_crumb == item.text ? 'background: -webkit-linear-gradient(left, #da7202, #f6ff00); -webkit-background-clip: text; -webkit-text-fill-color: transparent;' : 'color: #bcbcbc;'"
                >
                  <h3>
                    <span
                      :style="active_bread_crumb == item.text ? 'color:#EC6A30FF;' : 'color: #bcbcbc;'"
                      class="font_evog"
                    >
                      {{ item.text.toUpperCase() }}
                    </span>
                  </h3>
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-card-title>
        </v-card>
      </v-container>
  
      <!-- Scrollable Content -->
      <div class="scrollable-content">
        <v-card  height="auto">
          <v-card-text>
                <v-container class="custom-margin" >
                    <RaffleSettingsComponent v-if="active_bread_crumb == 'Raffle'" :event_id="filters.event_id" />
                    <NominationSettingsComponent v-if="active_bread_crumb == 'Nomination & Voting'" :event_id="filters.event_id" />
                    <ExpansionSettingsComponent v-if="active_bread_crumb == 'Expansions'" @raffleReset="raffleReset" :event_id="filters.event_id" />
                    <PreferenceSettingsComponent v-if="active_bread_crumb == 'Event Preferences'" :event_id="filters.event_id" />
                </v-container>
          </v-card-text>
  
          <!-- Fixed Actions at the Bottom -->
          <v-card-actions class="fixed-actions">
            <v-row class="ma-1 pb-2">
              <v-col cols="4" class="ml-16">
                <v-container fluid class="d-flex justify-content-end pa-0">
                  <v-btn color="#EC6A30FF" @click="saveSettings()">
                    <span style="color:white;">Save Changes</span>
                  </v-btn>
                  <span class="pr-12">&nbsp;</span>
                </v-container>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4" v-if="user.super_admin">
                <v-container fluid class="text-end pa-0">
                  <v-btn color="#EC6A30FF" @click="triggerResetEvent(true)">
                    <span style="color:white;">Reset Data</span>
                  </v-btn>
                  <span class="pr-12">&nbsp;</span>
                </v-container>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </div>
  
      <!-- Dialog -->
      <v-dialog v-model="reset_data.dialog" persistent width="50%" :retain-focus="false">
        <v-card class="overflow-x-hidden">
          <v-card-title class="pa-3 pl-3 pb-0 border-bottom">
            <span class="text-h5">Reset Event Details</span>
            <v-row class="m-0">
              <v-col cols="pull-right-10 p-2">
                <v-btn text icon small color="gray" class="float-right" @click="triggerResetEvent(false)">
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-container class="pb-0 mt-1" fluid>
              <v-row no-gutters v-for="(item, i) in resetTables" :key="i">
                <v-col cols="3" v-for="(data, index) in item.list" :key="index">
                  <v-checkbox v-model="reset_data.to_delete_tables" :label="data.text" :value="data.value" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions
              class="text-center"
          >
              <v-row class="ma-1 pb-2">
                  <v-col cols="12">
                      <v-btn
                          @click="resetDataNow()"
                      >
                          <span>Submit</span>
                      </v-btn>
                      <span class="pr-12">&nbsp;</span>
                  </v-col>
              </v-row>
          </v-card-actions>
          <!-- <v-card-actions class="text-center" style="border-top: 1px solid #000000; margin-top: auto;">
            <v-row class="ma-1 pb-2">
              <v-col cols="4">
                <v-container fluid class="d-flex justify-content-end pa-0">
                  <v-btn color="#EC6A30FF" @click="saveSettings()">
                    <span style="color:white;">Save Changes</span>
                  </v-btn>
                  <span class="pr-12">&nbsp;</span>
                </v-container>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4" v-if="user.super_admin">
                <v-container fluid class="text-end pa-0">
                  <v-btn color="#EC6A30FF" @click="triggerResetEvent(true)">
                    <span style="color:white;">Reset Data</span>
                  </v-btn>
                  <span class="pr-12">&nbsp;</span>
                </v-container>
              </v-col>
            </v-row>
          </v-card-actions> -->
        </v-card>
      </v-dialog>
    </div>
  </template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from 'vuex';
import RaffleSettingsComponent from '@/components/modules/settings/content/RaffleSettingsComponent.vue';
import NominationSettingsComponent from '@/components/modules/settings/content/NominationSettingsComponent.vue'
import ExpansionSettingsComponent from '@/components/modules/settings/content/ExpansionSettingsComponent.vue'
import PreferenceSettingsComponent from '@/components/modules/settings/content/PreferenceSettingsComponent.vue';

export default {
    data(){
        return {
            loaded: false,
            totalItems: 0,
            options: {},
            search: "",
            filter:{
                search:""
            },
            active_bread_crumb:'Raffle',
            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
            },
            tab: null,
            items:[],
            breadcrumbs: [
                
                {
                    text: 'Raffle',
                    disabled: true,
                },
                {
                    text: 'Nomination & Voting',
                    disabled: true,
                },
                {
                    text: 'Expansions',
                    disabled: false,
                },
                {
                    text: 'Event Preferences',
                    disabled: false,
                },
            ],
            settings_form:[],
            willReset: false,
            filters:{
                event_id: null,
            },
            dropdowns: {
                event_list: []
            },
            default: {
                event_id: 1
            },
            user: {},
            resetTables: [
                {
                    title: 'Statuses',
                    list:[
                        {text: 'Raffle Winners', value: 'raffle_winners'},
                        {text: 'Attendance', value: 'attendance'},
                        {text: 'Nomination', value: 'nomination'},
                    ]
                },
                {
                    title: 'Data',
                    list: [
                        {text: 'Guest List', value: 'guest_list'},
                        {text: 'Company List', value: 'company_list'},
                        {text: 'Company Tickets', value: 'company_tickets'},
                        {text: 'Share Your Moments', value: 'share_your_moments'},
                    ]
                }
            ],
            reset_data:{
                dialog: false,
                to_delete_tables: [
                    'raffle_winners',
                    'company_tickets',
                    'attendance',
                    'nomination',
                    'share_your_moments',
                    'guest_list',
                    'company_list',
                ]
            }
        }
    },
    components:{
        RaffleSettingsComponent,
        NominationSettingsComponent,
        ExpansionSettingsComponent,
        PreferenceSettingsComponent,
    },
    mounted(){
        // document.addEventListener("keydown", this.handleKeyDown);
        this.getEventDetailDropdown();
        this.setDefaults();
        this.getUser();
    },
    beforeUnmount() {
        window.removeEventListener('beforeunload', this.handleKeyDown);
    },
    computed:{
        ...mapGetters([
            'DIALOGS',
            'GET_FORM_SETTINGS',
            'GET_EVENT_ID',
            'GET_PREFERENCES',
            'GET_PREFERENCES_NEW_DATA'
        ])
    },
    methods:{
        bread_crumb_isActive(item){
            this.active_bread_crumb = item.value
        },
        getPreferenceImagePath(parameter) {
        const preference = this.preferences.find(x => x.parameter === parameter);
        return preference ? preference.value : '';
        },
        saveSettings(){
            if(this.willReset){
                Swal.fire({
                    title: 'Are you sure you want to save?',
                    text: `Raffle winners will reset.`,
                    icon:'question',
                    showConfirmButton: true,
                    showCancelButton  :  true,
                }).then((action)=>{
                    if(action.isConfirmed){
                        this.triggerSave();
                        
                    }
                    
                })
                
            }else{
                this.triggerSave();
            }
        },
        viewHrSettings(item){
            this.$store.commit("HR_SETTINGS_VIEW", item);
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "View");
        },
        editHrSettings(item){
            this.$store.commit("HR_SETTINGS_VIEW", item);
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "Update");
        },
        initialForm(){
            this.settings_form = []
        },
        changeActive(item){
            this.active_bread_crumb = item.text
        },
        handleKeyDown(event) {
        // Check if Ctrl + S is pressed
            if (event.ctrlKey && event.key === "s") {
                event.preventDefault(); // Prevent default browser save behavior
                this.saveSettings();
            }
        },
        raffleReset(confirm) {
            this.willReset = confirm;
        },
        triggerSave(){
            let payload = {
                data : this.settings_form.data,
                type: this.settings_form.type,
                url: "moduleSettings",
                raffleReset: this.willReset,
                event_id: this.GET_EVENT_ID
            }
            this.$store.dispatch("urlPost", payload).then(response=>{
                Swal.fire(response.icon.toUpperCase(), response.message, response.icon).then(() => {
                    this.$store.commit('PREFERENCES_NEW_DATA', true);
                    
                });
                
            })
        },

        getEventDetailDropdown(){
            let payload = {
                url: 'eventDetailDropdown'
            }
            this.$store.dispatch('urlGet', payload).then((res)=>{
                this.dropdowns.event_list = res.data;
            })
        },
        setDefaults(){
            Object.assign(this.filters,{
                event_id: this.default.event_id
            });
            // this.getSettings();
        },
        // getSettings(){
        //     this.$store.commit('EVENT_ID', this.filters.event_id);
        //     this.$store.commit('NEW_DATA', true);
        // },
        redirectToEvent(){
            let payload = {
                event_id: this.GET_EVENT_ID,
                url: 'getEventMD5'
            }
            this.$store.dispatch('urlPost', payload).then((res)=>{
                window.open(`${process.env.VUE_APP_API_HOST_CLIENT}?ec=${res.link}`)
            })
        },
        getUser(){
            let payload = {
                url: 'user'
            }
            this.$store.dispatch('urlGet', payload).then((res)=>{
                this.user = res;
            })
        },
        triggerResetEvent(value){
            this.reset_data.dialog = value;
            if(value){
                this.reset_data.to_delete_tables = [
                ]
            }
        },
        resetDataNow(){
            Swal.fire({
                title: 'Are you sure you want to reset?',
                text: `Event Related data will be cleared.`,
                icon:'question',
                showConfirmButton: true,
                showCancelButton  :  true,
            }).then((action)=>{
                if(action.isConfirmed){
                    let payload = {
                        url: "resetEventData",
                        event_id: this.GET_EVENT_ID,
                        to_delete: this.reset_data.to_delete_tables
                    }
                    this.$store.dispatch("urlPost", payload).then(response=>{
                        Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                        this.$store.commit('NEW_DATA', true);
                    })
                }
            })
        }
    },
    watch:{
        options: {
            handler(v) {
                
            },
            deep: true,
        },
        GET_FORM_SETTINGS:{
            handler(val){
                this.settings_form = val
                this.$store.commit('NEW_DATA', true);
                
            },
            deep: true
        },
      
    }
}
</script>
<style scoped>
.fixed-title-container {
  position: fixed;
  top: 125px;
  left: 0;
  right: 0;
  z-index: 1;
}

.fixed-title {
  position: relative;
  top: 0px;
  background:#1e1e1e;
  
}
.custom-margin{
    margin-top: 75px;
}



.fixed-card-text {
  position: fixed;
  top: 14px; 
  overflow-y: auto;
  background-color: #1e1e1e; 
  z-index: 10;
  height:500;
}

.selected {
    opacity: 100%;
}
.fixed-actions {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color:#1e1e1e; 
  
  z-index: 10; /* Ensure it's above other content */
}
.v-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.non-selected {
    opacity: 40%;
}
.v-breadcrumbs__item{
    cursor: pointer;
}

.font_evog{
    cursor:pointer; 
    font-family: Evogria;
}
.modal_title {
        position: sticky;
        top: 0;
        z-index: 10;
   
  height: 100%;
  margin: 0;
  overflow: hidden; /* Prevent scrolling on the entire page */
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
}

header {
  flex-shrink: 0; /* Ensure the header does not shrink */
  background-color: #333; /* Background color for header */
}

.content-container {
  flex: 1;
  overflow-y: auto; /* Enable vertical scrolling for content */ /* Optional padding */
  max-height: calc(100vh - 60px); /* Adjust based on header height */
  /* Ensure content container takes up the remaining space after the header */
}

main {
  display: flex;
  flex-direction: column;
  height: 100%;
color: white;
} 
</style>
````