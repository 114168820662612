<template>
    <div class="container-fluid">
        <div class="row d-flex justify-content-center align-items-start" style="min-height: 100vh;">
            <div class="col-md-10 col-lg-6 col-sm-10 g-mt-10">
                <div class="g-pa-40 g-mb-30 vacant_positions" role="alert">
                    <div class="row">
                        <div class="col-lg-12 col-sm-12 g-pa-2">
                            <div class="form-group g-mb-20">
                                <v-form>
                                    <v-row>
                                        <v-text-field @keyup.enter="submitEmployeeId" label="ENTER REFERENCE NUMBER" persistent-hint regular tile outlined
                                            clearable color="#17468f" class="fill-width mx-auto" v-model="employeeId">
                                        </v-text-field>
                                        <v-btn @click="submitEmployeeId" class="mx-auto" xs12 sm6 color="#17468f" dark solo x-large>
                                            ENTER
                                        </v-btn>
                                    </v-row>
                                    <v-row justify="center">
                                        <div class="col-lg-12 col-sm-12 g-pa-2 d-flex justify-content-center">
                                            <span style="font-family: Evogria; font-size: 1rem; font-weight: bold;">
                                                <label class="g-mb-10" style="color:white">
                                                    OR
                                                </label>
                                            </span>
                                        </div>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-dialog v-model="uploadModalOpen" max-width="350">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn @click="clearEmployeeId" elevation="24" color="#17468f" icon rounded
                                                    fab plain v-bind="attrs" v-on="on">
                                                    <v-icon size="35" left>
                                                        mdi-qrcode-scan
                                                    </v-icon>
                                                    <span class="underline-animation" style="font-family: Evogria; font-size: 1.8rem; font-weight: bold;">
                                                        Scan Now!
                                                    </span>
                                                </v-btn>
                                            </template>
                                            <v-card style="font-family: Evogria;" class="gray-border">
                                                <v-card-title @mouseover="hovered = true" @mouseleave="hovered = false"
                                                    :class="{ 'orange-text': hovered }">
                                                    SCAN YOUR QR CODE
                                                </v-card-title>
                                                <v-divider :thickness="20"></v-divider>
                                                <v-card-text>
                                                    Choose an action:
                                                </v-card-text>
                                                <v-card-text class="text-center" @mouseover="hovered = true" @mouseleave="hovered = false"
                                                    :class="{ 'orange-text': hovered }">
                                                    <input @change="handleFileUpload" ref="fileInput" type="file" accept="image/*"
                                                    style="display: none" />
                                                    <v-btn @click="chooseFile" class="underline-animation">
                                                        <v-icon size="22" left>
                                                            mdi-paperclip
                                                        </v-icon>
                                                        Upload QR Code
                                                    </v-btn>
                                                </v-card-text>
                                                <v-spacer></v-spacer>
                                                <v-card-text class="text-center">
                                                    OR
                                                </v-card-text>
                                                <v-spacer></v-spacer>
                                                <v-card-text class="text-center" @mouseover="hovered = true" @mouseleave="hovered = false"
                                                    :class="{ 'orange-text': hovered }">
                                                    <v-btn @click="cancelUpload" class="underline-animation">
                                                        <v-icon size="22" left>
                                                            mdi-camera
                                                        </v-icon>
                                                        Use Camera
                                                    </v-btn>
                                                </v-card-text>
                                            </v-card>
                                            <v-card id="qr-code-full-region"></v-card>
                                            <v-btn class="orange-border" v-if="qrScannerRunning" @click="closeQRScanner" color="#17468f" dark>
                                                Close Scanner
                                            </v-btn>
                                        </v-dialog>
                                    </v-row>
                                </v-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Swal from 'sweetalert2';
    import axios from 'axios';
    import { Html5Qrcode } from 'html5-qrcode';
    import jsQR from 'jsqr';
    export default {
        name: 'HelloWorld',
    data() {
        return {
            employeeId: '',
            remainingTickets: '',
            Html5Qrcode: null,
            scannedqrcodes: '',
            qrScannerRunning: false, 
            dialog: false,
            uploadModalOpen: false,
            uploadFileModalOpen: false,
            selectedFile: null,
            selectedFileName: null,
            hovered: false,
            fullScreenDialogOpen: true,
            items: [1],
        }
    },
    beforeRouteLeave(to, from, next) {
    next()
    },
    methods: {
        showQRScanner() {
		if (!this.qrScannerRunning) {
			let Html5Qrcodes = new Html5Qrcode("qr-code-full-region");
			let config = {
			fps: 30,
			qrbox: {
				width: 250,
				height: 250
			}
			};
			Html5Qrcodes.start({
			facingMode: "environment"
			}, config, this.onScanSucess)
			this.qrScannerRunning = true;
		}
		},
		onScanSucess(decodeResult) {
    if (!this.qrScannerRunning) {
        return;
    }
    this.scannedqrcodes = decodeResult;
    this.qrScannerRunning = false;
    this.submitEmployeeId(this.scannedqrcodes);
},
async submitEmployeeId() {
    try {
        // await Swal.fire({
        //     title: 'Success!',
        //     allowOutsideClick: false,
        //     showCancelButton: false,
        //     // showConfirmButton: false, // Change to true to show the confirm button
        //     // showLoaderOnConfirm: false, // Show loader on confirm button
        // });    Swal.showLoading();

        let response = await axios.post(process.env.VUE_APP_API_HOST + '/api/check-employee-bar', {
            employee_id: this.employeeId || this.scannedqrcodes
        });

        Swal.showLoading();
        this.remainingTickets = response.data.remaining_tickets;

        if (response.data.message === "Tickets fully consumed!") {
            await Swal.fire({
                title: 'Employee Information',
                text: response.data.message,
                icon: 'info',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'orange-button',
                }
            });
        } else {
            await Swal.fire({
                title: 'Ticket Consumed!',
                html: `Hello! <br> ${response.data["EmployeeData"].full_name} <br> of <br> ${response.data["company_name"]}
                <br> Remaining tickets: ${response.data["mobile_bar_count"]}`,
                icon: 'success',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'orange-button',
                }
            });
        }
    } catch (error) {
        if (error.response.status === 500) {
            await Swal.fire({
                title: "<strong><u>NOT FOUND</u></strong>",
                text: error.response.data.message,
                icon: 'warning',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'orange-button',
                }
            });
        } else {
            console.error(error);
        }
    } finally {
        // Reset both employeeId and scannedqrcodes
        this.employeeId = '';
        this.scannedqrcodes = '';
        this.qrScannerRunning = false;
        this.uploadModalOpen = false;
    }
},
        reloadPage() {
    window.location.reload();
    },

        toggleUploadModal() {
        this.uploadModalOpen = !this.uploadModalOpen;
        },
        uploadFile() {
        this.toggleUploadModal();
        },
        cancelUpload() {
        this.showQRScanner(); 
        },
        closeQRScanner() {
            this.reloadPage();
        this.qrScannerRunning = false;
        this.uploadModalOpen = false;
        },
        async handleFileUpload(event) {
        let file = event.target.files[0];
        if (file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async () => {
            let image = new Image();
            image.src = reader.result;
            image.onload = async () => {
                let canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;
                let ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0);
                let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                let qrCode = jsQR(imageData.data, imageData.width, imageData.height);
                if (qrCode) {
                this.scannedqrcodes = qrCode.data;
                this.submitEmployeeId();
                } else {
                console.error('QR code not found or could not be decoded.');
                }
                this.uploadModalOpen = false;
            };
            };
        }
        // this.$refs.fileInput.value = null;
        },
        chooseFile() {
        this.$refs.fileInput.click();
        },
        handleFileChange(event) {
        let file = event.target.files[0];
        this.selectedFile = file;
        this.selectedFileName = file ? file.name : null;
        },
        clearFile() {
        this.selectedFile = null;
        this.selectedFileName = null;
        },
        clearEmployeeId() {
        this.employeeId = '';
        }
    },
    created() {
        document.title = 'Mobile Bar';
    },
    }
</script>

<style scoped>
    @import '/src/assets/css/HelloWorld.css';
</style>
