import { render, staticRenderFns } from "./ActivitiesComponentView.vue?vue&type=template&id=881fb938&scoped=true"
import script from "./ActivitiesComponentView.vue?vue&type=script&lang=js"
export * from "./ActivitiesComponentView.vue?vue&type=script&lang=js"
import style0 from "./ActivitiesComponentView.vue?vue&type=style&index=0&id=881fb938&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "881fb938",
  null
  
)

export default component.exports