<template>
    <v-form ref="form" v-model="valid" lazy-validation style="height:875px;">
        <v-card class="overflow-x-hidden system_dialog_color system_admin_font_family_primary">
            <v-card-title class="pa-3 pl-3 pb-0 border-bottom system_dialog_color" >
                <span class="h5 system_dialog_font_color">Raffle Prize Details</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeDialog()"
                        >
                            <span class="system_dialog_font_color"><v-icon>mdi-close-circle</v-icon></span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text class="system_dialog_color">
                <v-container class="pb-0 mt-1 " fluid>
                    <v-row no-gutters class="pa-0">
                        <v-col cols="12" class="pa-0 pb-2">
                            <v-hover v-model="isHover">
                                <template v-slot="{ hover }">
                                    <v-card>
                                        <v-container fluid class="picture">
                                            <v-img :src="form.prize_image" contain aspect-ratio="1.7">
                                            </v-img>
                                        </v-container>
                                        <v-fade-transition style="opacity:0.5;">
                                            <v-overlay absolute :color="hover ? 'white' : ''"  class="d-flex flex-row justify-content-center align-items-center">
                                                    <v-row>
                                                        <v-tooltip bottom color="#2590ff">
                                                            <template v-slot:activator="{ on, attrs }" >
                                                                <v-btn
                                                                    v-show="hover == true"
                                                                    v-on="on"
                                                                    v-bind="attrs"
                                                                    :disabled="disabled_view"
                                                                    style="opacity:1; cursor: pointer;"
                                                                    class="ma-2"
                                                                    color="#2590ff"
                                                                    text
                                                                >
                                                                    <label for="upload-file"><span class="upload-file system_dialog_font_color"><v-icon x-large>mdi-tray-arrow-up</v-icon></span></label>
                                                                </v-btn>
                                                            </template>
                                                            <span>Upload Image</span>
                                                        </v-tooltip>
                                                    </v-row>
                                                <input  class="d-none" id="upload-file" type="file" ref="fileInput" @change="uploadImage" accept="image/png, image/jpeg"/>
                                            </v-overlay>
                                        </v-fade-transition>
                                    </v-card>
                                </template>
                            </v-hover>
                        </v-col>
                        <v-col cols="12" class="pa-0">
                            <v-text-field
                                v-model="form.code"
                                :disabled="disabled_view"
                                label="Code"
                                outlined
                                class="system_dialog_text_color"
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pa-0">
                            <v-text-field
                                v-model="form.name"
                                :disabled="disabled_view"
                                label="Name"
                                class="system_dialog_text_color"
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pa-0" v-if="ACTION != 'Submit' && raffleSettings.data_grouping == 2">
                            <!-- :item-disabled="checkExistingWinnerCompany" -->
                            <v-autocomplete
                                v-model="form.ticket_num"
                                :items="dropdowns.company_tickets"
                                :disabled="disabled_view"
                                item-text="value"
                                label="Winner Ticket #"
                                class="system_dialog_text_color"
                                auto-select-first
                                clearable
                                outlined
                                dense
                                @input="getCompanyNameOfTicket()"
                            >
                            </v-autocomplete>
                            <!-- <v-text-field
                                v-model="form.ticket_num"
                                :disabled="disabled_view"
                                label="Winner Ticket #"
                                class="system_dialog_text_color"
                                readonly
                                outlined
                                required
                                dense
                            ></v-text-field> -->
                        </v-col>
                        <v-col cols="12" class="pa-0" v-if="ACTION != 'Submit' && raffleSettings.data_grouping == 2">
                            <v-text-field
                                v-model="form.company_name"
                                :disabled="disabled_view"
                                label="Winner Company Name"
                                class="system_dialog_text_color"
                                readonly
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pa-0" v-if="ACTION != 'Submit' && raffleSettings.data_grouping == 1">
                            <v-text-field
                                v-model="form.ticket_num"
                                :disabled="disabled_view"
                                label="Winning Ticket #"
                                class="system_dialog_text_color"
                                readonly
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pa-0" v-if="ACTION != 'Submit' && raffleSettings.data_grouping == 1">
                            <v-text-field
                                v-model="form.employee_name"
                                :disabled="disabled_view"
                                label="Employee Name"
                                class="system_dialog_text_color"
                                readonly
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pa-0">
                            <v-text-field
                                v-model="form.winner_count"
                                label="Winner Count"
                                type="number"
                                class="system_dialog_text_color"
                                :disabled="disabled_view"
                                min="0"
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pa-0">
                            <v-text-field
                                v-model="form.order"
                                label="Order"
                                type="number"
                                min="0"
                                class="system_dialog_text_color"
                                :disabled="disabled_view"
                                readonly
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pa-0">
                            <v-checkbox
                                v-model="form.suspense_animate"
                                :true-value="1"
                                :false-value="0"
                                :disabled="disabled_view"
                                label="Suspense Animation"
                                class="system_dialog_text_color"
                                outlined
                                required
                                dense
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions
                class="text-center system_dialog_color"
                style="border-top: 1px solid #000000"
            >
                <v-row class="ma-1 pb-2">
                    <v-col cols="12" v-if="actions != 'View'">
                        <v-btn
                            @click="
                                actions == 'Submit'? addRafflePrize() : updateRafflePrize()"
                            :disabled="disabled_view"
                            class="system_dialog_button_color"
                        >
                            <span class="system_dialog_font_color">{{ actions }}</span>
                        </v-btn>
                        <span class="pr-12">&nbsp;</span>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-form>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            valid: false,
            overlay: false,
            disabled_view: false,
            errors: {
                required: [(v) => !!v || "Field is required"],
                number: [
                    (v) => !!v || "Cost is required",
                    (v) => !isNaN(v) || "Input must be a number",
                ],
            },
            form: {},
            isHover: false,
            raffleSettings: {
				data_grouping: null
			},
            dropdown_function_name: [
                'getCompanyTickets'
            ],
            dropdowns:{
                company_tickets: [],
                guest_lists: [],
            },
            company_id_winners: []
        };
    },
    computed: {
        ...mapGetters([
            "ACTION",
            "DIALOGS",
            "GET_DIALOG_VIEW",
            "GET_EVENT_ID"
        ]),
        actions() {
            this.getDropdowns();
            if (this.ACTION == "Submit") {
                this.disabled_view = false;
                this.isViewBtn = false;
                this.getLatestOrderPrizes()
                return "Submit";
            } else if (this.ACTION == "Update") {
                this.disabled_view = false;
                this.isViewBtn = true;
                this.getRafflePrizeData();
                return "Update";
            } else if (this.ACTION == "View") {
                this.disabled_view = true;
                this.isViewBtn = true;
                this.getRafflePrizeData();
                return "View";
            }
        },
    },
    created() {
        this.initialForm();
    },
    mounted() {
        this.getRaffleDurationSettings();
    },
    methods: {
        initialForm() {
            this.form = {
                code                : '',
                name                : '',
                order               : '',
                prize_image         : '',
                suspense_animate    : 0,
            };
            this.$refs.fileInput.value = null;
        },
        height(width) {1
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return width;
                case "lg":
                    return width;
                case "xl":
                    return width;
            }
        },
        closeDialog() {
            this.$store.commit("DIALOG", false);
            this.$store.commit("ACTION", "");
        },
        async addRafflePrize() {
            this.$refs.form.validate();

            let code_swal_text = this.form.code ? '' : 'Code';
            let name_swal_text = this.form.name ? '' : 'name';
            let winner_count_swal_text = this.form.winner_count ? '' : 'winner_count';
            let order_swal_text = this.form.order ? '' : 'order';
            let array_text =
            [
                code_swal_text,
                name_swal_text,
                order_swal_text,
                winner_count_swal_text
            ]
            let filtered = array_text.filter(function (el) {
                return el.replace(',', '');
            })

            if (
                this.form.code &&
                this.form.name &&
                this.form.order &&
                this.form.winner_count
            ) {
                let payload = {
                    url: "rafflePrizes",
                    ...this.form,
                    event_id: this.GET_EVENT_ID	
                }
                await this.$store.dispatch('urlPost', payload).then(response=>{
                    Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                    this.$store.commit('NEW_MASTER_DATA', true);
                    this.$store.commit("DIALOG", false);
                })
            } else {
                Swal.fire({
                    text: `Fill Required Fields (${filtered})`,
                    icon: 'warning',
                    allowOutsideClick: false,
                });
            }
        },
        async updateRafflePrize() {
            let payload = {
                url: "rafflePrizes",
                id: this.form.id,
                ...this.form,
                event_id: this.GET_EVENT_ID
            }
            await this.$store.dispatch("urlPut", payload).then((response) => {
				Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                this.$store.commit('NEW_MASTER_DATA', true)
				this.closeDialog();
			}).catch((error)=>{
                if(error.response.status == 401){
                    Swal.fire({
                        title: 'Unauthenticated!',
                        text: '',
                        icon: 'error',
                        allowOutsideClick: false
                    }).then((result)=>{
                        if(result.isConfirmed){
                            localStorage.removeItem("token");
                            window.location.reload();
                            this.$router.push('/');
                        }
                    })
                }
            });
        },
        async getRafflePrizeData() {
            this.form.id                    = this.GET_DIALOG_VIEW.id;
            this.form.code                  = this.GET_DIALOG_VIEW.code;
            this.form.name                  = this.GET_DIALOG_VIEW.name;
            this.form.winner_count          = this.GET_DIALOG_VIEW.winner_count;
            this.form.order                 = this.GET_DIALOG_VIEW.order;
            this.form.prize_image           = this.GET_DIALOG_VIEW.prize_image;
            this.form.suspense_animate      = this.GET_DIALOG_VIEW.suspense_animate;
            if(!_.isEmpty(this.GET_DIALOG_VIEW.winner_details)){
                if(this.raffleSettings.data_grouping == 1){

                }else{
                    console.log(this.GET_DIALOG_VIEW.winner_details)
                    this.form.company_name = this.GET_DIALOG_VIEW.winner_details.name;
                    this.form.ticket_num = this.GET_DIALOG_VIEW.winner_details.ticket_num;
                }
            }
        },
        uploadImage(event) {
            let that = this;
			let reader = new FileReader()
			reader.addEventListener('load', () => {
				that.form.prize_image = reader.result
			})
			let file = event.target.files[0];
			let url = reader.readAsDataURL(file);
		},
        getLatestOrderPrizes(){
            let payload = {
                url: 'getLatestOrderPrizes'
            }
            this.$store.dispatch('urlPost', payload).then((res)=>{
                this.form.order = res;
            }).catch((error)=>{
                if(error.response.status == 401){
                    Swal.fire({
                        title: 'Unauthenticated!',
                        text: '',
                        icon: 'error',
                        allowOutsideClick: false
                    }).then((result)=>{
                        if(result.isConfirmed){
                            localStorage.removeItem("token");
                            window.location.reload();
                            this.$router.push('/');
                        }
                    })
                }
            });
        },
        async getRaffleDurationSettings(){
			let payload = {
                type: 'Expansions',
                url: 'moduleSettings'
            };
            await this.$store.dispatch("urlGet", payload).then((res)=>{
                res.forEach(e=>{
                    Object.keys(this.raffleSettings).filter(el=>{
                        if(el == e.parameter){
                            this.raffleSettings[el] = parseFloat(e.value)
                        }
                    });
                })
            })
		},
        getDropdowns(){
            this.dropdown_function_name.forEach(e=>{
                this[e]();
            })
        },
        getCompanyTickets(){
            let payload = {
                url: 'getCompanyTickets',
            }
            this.$store.dispatch('urlPost', payload).then(res=>{
                this.dropdowns.company_tickets = res.lists
                this.company_id_winners = res.company_id_winners
            })
        },
        getCompanyNameOfTicket(){
            let ticket_company_name = this.dropdowns.company_tickets.find(x=> x.value == this.form.ticket_num)?.company_name;
            console.log(ticket_company_name)
            this.form.company_name = ticket_company_name
            this.$forceUpdate();
        },
        checkExistingWinnerCompany(item){
            if(this.company_id_winners.includes(item.company_id)){
                return true
            }
            return false
        }
    },
    watch: {
        DIALOGS: {
            handler(val) {
                if (!val) {
                    this.initialForm();
                    this.$store.commit('DIALOG_VIEW', {})
                }else{
                    // if(this.ACTION == 'Submit'){
                    //     this.getLatestOrderPrizes()
                    // }
                }
            },
        },
    },
};
</script>
<style scoped>
    .modal_title {
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: white;
    }
</style>
