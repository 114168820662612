import Vue from "vue";
import VueRouter from "vue-router";
import EmployeeRegistration from "../EmployeeRegistration.vue";
import ConfirmRegistration from "../ConfirmRegistration.vue";
import ConfirmRegistrationComponent from "../ConfirmRegistrationComponent.vue";
import RaffleDraw from "../RaffleDraw.vue";
import Dashboard from "../Dashboard.vue";
import LandingPage from "../LandingPage.vue";
// import AfterConfirmComponent from "../AfterConfirmComponent.vue";
import NominationListComponent from "@/components/modules/apps/NominationListComponent.vue"
import VoteListComponentvue from "@/components/modules/apps/VoteListComponent.vue"

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
    },
    {
        path: '/landing',
        name: 'LandingPage',
        component: LandingPage,
    },
    {
        path: '/register',
        name: 'EmployeeRegistration',
        component: EmployeeRegistration,
    },
    // {
    //     path: '/confirmation',
    //     name: 'ConfirmRegistration',
    //     component: ConfirmRegistration,
    // },
    {
        path: '/raffle/:id',
        name: 'RaffleDraw',
        component: RaffleDraw,
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
    },
    {
        path: '/ConfirmationFillUp',
        name: 'ConfirmRegistrationComponent',
        component: ConfirmRegistrationComponent,
    },
    {
        path: '/nomination/:id/:event_id',
        name: 'Nomination List',
        component: NominationListComponent,
    },
    {
        path: '/vote_list/:id/:event_id',
        name: 'Vote List',
        component: NominationListComponent,
        meta: {
            title: 'Vote List',
        }
    },
];

const router = new VueRouter({
    routes
});

// router.beforeEach((to, from, next) => {
//     if (!localStorage.getItem("token")) {
//         next();
//     } else if (!localStorage.getItem("token")) {
//         next("/");
//     } else {
//         if (typeof to.meta.title !== "undefined") {
//             document.title = `${process.env.VUE_APP_TITLE} - ${to.meta.title}`;
//         } else {
//             document.title = `${process.env.VUE_APP_TITLE}`;
//         }
//         next();
//     }
// });

export default router;
